import liffGroupBuying from "./middlewares/liff/groupBuying";
import liffCoreGroupBuying from "./middlewares/liff/coreGroupBuying";
import liffOrder from "./middlewares/liff/order";
import liffMemberCard from "./middlewares/liff/memberCard";
import liffChatroom from "./middlewares/liff/chatroom";
import liffRedeemCode from "./middlewares/liff/redeemCode";
import customerBind from "./middlewares/liff/customerBind";
import huggies from "./middlewares/liff/huggies";
import huggiesUseApiClient from "./middlewares/liff/huggiesUseApiClient";
import huggiesCoupon from "./middlewares/liff/huggies-coupon";
import huggiesSample from "./middlewares/liff/huggies-sample";
import liffGeneralStoreInit from "./middlewares/liffGeneralStoreInit";
import liffAuth from "./middlewares/liff/v2/auth";
import loadModules from "./middlewares/liff/v2/loadModules";
import checkPermission from "./middlewares/liff/v2/checkPermission";

export default [
  {
    path: "/:org_code/liff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/Entry.vue"
      ),
  },
  {
    path: "/:orgCode/liff/pdf/:meta",
    component: () =>
      import(/* webpackChunkName: 'LiffPDFViewer' */ "@/pages/Liff/PDF.vue"),
    meta: {
      middleware: [liffChatroom],
    },
  },
  {
    path: "/:org_code/liff/files/:uploadFile",
    component: () =>
      import(/* webpackChunkName: 'LiffFileViewer' */ "@/pages/Liff/UploadFile.vue"),
  },
  // 搬移到einvoice
  // {
  //   path: "/:orgCode/liff/invoice/scan",
  //   component: () =>
  //     import(/* webpackChunkName: 'LiffPDFViewer' */ "@/pages/Liff/Invoice/Scanner.vue"),
  //   meta: {
  //     middleware: [liffGeneralStoreInit],
  //   },
  // },
  /**
   * 李向月連
   * Leeselect
   */
  {
    path: "/:org_code/liff/portal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Portal.vue"
      ),
    meta: {
      middleware: [customerBind],
    },
  },
  {
    path: "/:org_code/liff/bind",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Bind"
      ),
    meta: {
      middleware: [customerBind],
    },
  },

  {
    path: "/:org_code/liff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/AuthLayout.vue"
      ),
    meta: {
      middleware: [customerBind],
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Index.vue"
          ),
      },
      {
        path: "login",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Login.vue"
          ),
      },
      // {
      //   path: "register",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Register.vue"
      //     ),
      // },
      {
        path: "forgotpassword",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Forgotpassword.vue"
          ),
      },
      {
        path: "follow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/CustomerBind/Follow.vue"
          ),
      },
    ],
  },
  {
    path: "/:org_code/liff/url/:url_code",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
    import(
      /* webpackChunkName: 'LiffUrl' */ "@/pages/Liff/Url/Url.vue"
    ),
  },

  /**
   * Sales Module
   */
  {
    path: "/:org_code/liff/share_my_card",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/ShareMyCard.vue"
      ),
  },
  {
    path: "/:org_code/liff/sales",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/SalesLayout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit],
    },
    children: [
      {
        name: "LiffSalesCustomerBindStaff",
        path: "customer_bind_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/CustomerBindStaff.vue"
          ),
      },
      {
        name: "LiffSalesStaffSearch",
        path: "staff_search",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/StaffSearch.vue"
          ),
      },
      {
        name: "LiffSalesOpenInLine",
        path: "open_in_line",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/OpenInLine.vue"
          ),
      },
    ],
  },
  {
    path: "/:org_code/liff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/SalesLayout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit],
    },
    children: [
      {
        path: "bind_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/BindStaff.vue"
          ),
      },
      {
        path: "unbind_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/UnbindStaff.vue"
          ),
      },
      {
        path: "staff_active",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/StaffActive.vue"
          ),
      },
      {
        path: "staff_edit",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/EditStaff.vue"
          ),
        meta: {
          middleware: [liffGeneralStoreInit],
        },
      },
      {
        path: "customer_form",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/CustomerForm.vue"
          ),
      },
      {
        path: "sales/customer_list",
        name: "LiffSalesCustomerList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/CustomerList.vue"
          ),
      },
      {
        path: "customer/:id",
        name: "LiffSalesCustomer",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Customer.vue"
          ),
      },
    ],
  },

  /**
   * Sales Module for 中租版本
   */
  {
    path: "/:org_code/liff/chailease/share_my_card",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/ShareMyCard.vue"
      ),
  },
  {
    path: "/:org_code/liff/chailease",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/ChaileaseSalesLayout.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Index.vue"
          ),
      },
      {
        path: "bind_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/BindStaff.vue"
          ),
      },
      {
        path: "unbind_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/UnbindStaff.vue"
          ),
      },
      {
        path: "staff_active",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/StaffActive.vue"
          ),
      },
      {
        path: "edit_staff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/EditStaff.vue"
          ),
      },
      {
        path: "customer_form",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/CustomerForm.vue"
          ),
      },
      {
        path: "customer_list",
        name: "LiffSalesCustomerList_Chailease",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/CustomerList.vue"
          ),
      },
      {
        path: "customer/:id",
        name: "LiffSalesCustomer_Chailease",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/Customer.vue"
          ),
      },
      {
        path: "customer_patch_upload",
        name: "LiffSalesCustomerPatchUpload_Chailease",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSales' */ "@/pages/Liff/Sales/Chailease/CustomerPatchUpload.vue"
          ),
      },
    ],
  },
  /**
   * 2022.06 huggies 好奇寶寶 Momo 點數活動
   */
  // 說明頁
  {
    path: "/:org_code/liff/huggies/member/home",
    name: "LiffHuggiesMomoMemberHome",
    component: () =>
      import(
        /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoHome"
      ),
  },
  // 首頁（這頁要拆出來，是因為它不需要 LayoutMomo）
  {
    path: "/:org_code/liff/huggies/member",
    name: "LiffHuggiesMomoMemberIndex",
    meta: {
      middleware: [huggiesUseApiClient],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoIndex"
      ),
  },
  {
    path: "/:org_code/liff/huggies/member",
    meta: {
      middleware: [huggiesUseApiClient],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/LayoutMomo"
      ),
    children: [
      {
        path: "profile",
        name: "LiffHuggiesMomoProfile",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoProfile"
          ),
      },
      {
        path: "redeem",
        name: "LiffHuggiesMomoRedeem",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoRedeem"
          ),
      },
      {
        path: "redeem-record",
        name: "LiffHuggiesMomoRedeemRecord",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoRedeemRecord"
          ),
      },
      {
        path: "point-record",
        name: "LiffHuggiesMomoPointRecord",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggiesMomo' */ "@/pages/Liff/Huggies/MomoPointRecord"
          ),
      },
    ],
  },
  /**
   * huggies 好奇寶寶
   */
  {
    path: "/:org_code/liff/huggies",
    meta: {
      middleware: [huggies],
    },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggies2022Sample' */ "@/pages/Liff/Huggies/LayoutSample.vue"
          ),
        children: [
          {
            path: "2022_sample_page",
            name: "LiffHuggiesSamplePage",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies2022Sample' */ "@/pages/Liff/Huggies/SamplePage.vue"
              ),
          },
          {
            path: "2022_sample",
            name: "LiffHuggiesSample",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies2022Sample' */ "@/pages/Liff/Huggies/Sample.vue"
              ),
            meta: {
              middleware: [huggiesSample],
            },
          },
          {
            path: "2022_sample_done",
            name: "LiffHuggiesSampleDone",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies2022Sample' */ "@/pages/Liff/Huggies/SampleDone.vue"
              ),
          },
        ],
      },
      {
        path: "2022_coupon_page",
        name: "LiffHuggiesCouponPage",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggies2022Coupon' */ "@/pages/Liff/Huggies/CouponPage.vue"
          ),
      },
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/Layout.vue"
          ),
        children: [
          {
            path: "terms",
            name: "LiffHuggiesTerms",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/Terms.vue"
              ),
          },
          {
            path: "register",
            name: "LiffHuggiesRegister",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/Register.vue"
              ),
          },
          {
            path: "validation",
            name: "LiffHuggiesValidation",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/Validation.vue"
              ),
          },
          {
            path: "register-done",
            name: "LiffHuggiesRegisterDone",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/RegisterDone.vue"
              ),
          },
        ],
      },
      {
        path: "coupon",
        component: () =>
          import(
            /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/LayoutCoupon"
          ),
        meta: {
          middleware: [huggiesCoupon],
        },
        children: [
          {
            path: "",
            name: "LiffHuggiesCoupons",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/Coupons"
              ),
          },
          {
            path: "ec",
            name: "LiffHuggiesCouponEC",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponEC"
              ),
          },
          {
            path: "ec-redeem",
            name: "LiffHuggiesCouponECRedeem",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponECRedeem"
              ),
          },
          {
            path: "ec-finished",
            name: "LiffHuggiesCouponECFinished",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponECFinished"
              ),
          },
          {
            path: "offline",
            name: "LiffHuggiesCouponOffline",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponOffline"
              ),
          },
          {
            path: "offline-redeem",
            name: "LiffHuggiesCouponOfflineRedeem",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponOfflineRedeem"
              ),
          },
          {
            path: "offline-finished",
            name: "LiffHuggiesCouponOfflineFinished",
            component: () =>
              import(
                /* webpackChunkName: 'LiffHuggies' */ "@/pages/Liff/Huggies/CouponOfflineFinished"
              ),
          },
        ],
      },
    ],
  },

  /**
   * Orders
   */
  // 20220818 前綴加上group-buying是因為這樣就可以不用去liff在設定一下路由了
  {
    path: "/:orgCode/liff/group-buying/order-list",
    name: "LiffOrderPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffOrders' */ "@/pages/Liff/Orders/Layout.vue"
      ),
    meta: {
      middleware: [liffOrder],
    },
    children: [
      {
        path: "/",
        name: "LiffOrders",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrders' */ "@/pages/Liff/Orders/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":status(processing|completed|invalid)",
        name: "LiffOrdersStatus",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrders' */ "@/pages/Liff/Orders/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "detail/:id",
        name: "LiffOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrders' */ "@/pages/Liff/Orders/Detail.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "edit/:id",
        name: "LiffOrderEdit",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrders' */ "@/pages/Liff/Orders/Edit.vue"
          ),
        meta: { boundOnly: true },
      },
    ],
  },

  /**
   * Core Group Buying 通用版團購
   */
  {
    path: "/:org_code/liff/share_groupbuying",
    name: "LiffShareCoreGroupBuying",
    component: () =>
      import(
        /* webpackChunkName: 'LiffShareGroupbuying' */ "@/pages/Liff/GroupBuying/ShareGroupbuying"
      ),
  },
  {
    path: "/:orgCode/liff/core-group-buying",
    name: "LiffCoreGroupBuyingPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/Layout.vue"
      ),
    meta: {
      middleware: [liffCoreGroupBuying, liffGeneralStoreInit],
    },
    children: [
      {
        path: "horizontal-list",
        name: "LiffCoreGroupBuyingListV2",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/HorizontalList.vue"
          ),
      },
      {
        path: "list",
        name: "LiffCoreGroupBuyingList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/ListOrShow.vue"
          ),
      },
      {
        path: "orders/list",
        name: "LiffCoreGroupBuyingOrderProcessingList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/Order/List.vue"
          ),
      },
      {
        path: "orders/completed-list",
        name: "LiffCoreGroupBuyingOrderCompletedList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/Order/List.vue"
          ),
      },
      {
        path: "orders/invalid-list",
        name: "LiffCoreGroupBuyingOrderInvalidList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/Order/List.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffCoreGroupBuyingShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoreGroupBuying' */ "@/pages/Liff/GroupBuying/CoreGroupBuying/ListOrShow.vue"
          ),
      },
    ],
  },

  /**
   * Group Buying
   */
  {
    path: "/:orgCode/liff/group-buying/redirect",
    name: "LiffGroupBuyingRedirect",
    component: () =>
      import(
        /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Redirect.vue"
      ),
  },
  {
    path: "/:orgCode/liff/group-buying",
    name: "LiffGroupBuyingPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Layout.vue"
      ),
    meta: {
      middleware: [liffGroupBuying],
    },
    children: [
      {
        path: "ui",
        name: "LiffGroupBuyingUI",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/UI.vue"
          ),
      },
      {
        path: "debug",
        name: "LiffGroupBuyingDebug",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Debug.vue"
          ),
      },
      {
        path: "auth/bind",
        name: "LiffGroupBuyingAuthBind",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Bind.vue"
          ),
        meta: { notBoundOnly: true },
      },
      {
        path: "auth/login",
        name: "LiffGroupBuyingAuthLogin",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Login.vue"
          ),
        meta: { notBoundOnly: true },
      },
      {
        path: "auth/register",
        name: "LiffGroupBuyingAuthRegister",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Register.vue"
          ),
        meta: { notBoundOnly: true },
      },
      {
        path: "auth/bound",
        name: "LiffGroupBuyingAuthBound",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Bound.vue"
          ),
      },
      {
        path: "auth/follow",
        name: "LiffGroupBuyingAuthFollow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Follow.vue"
          ),
      },
      {
        path: "auth/password",
        name: "LiffGroupBuyingAuthPassword",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/Password.vue"
          ),
        meta: { notBoundOnly: true },
      },
      {
        path: "auth/password-changed",
        name: "LiffGroupBuyingAuthPasswordChanged",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Auth/PasswordChanged.vue"
          ),
        meta: { notBoundOnly: true },
      },
      {
        path: "theme-group-buying",
        name: "LiffThemeGroupBuyingRedirect",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/ThemeGroupbuy/Redirect.vue"
          ),
        meta: {
          boundOnly: true,
        },
      },

      /**
       * orders
       */

      {
        path: "orders",
        name: "LiffGroupBuyingOrders",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Orders/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "orders/:status(processing|completed|invalid)",
        name: "LiffGroupBuyingOrdersStatus",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Orders/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "orders/:id",
        name: "LiffGroupBuyingOrdersShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Orders/Show.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "orders/:id/canceled",
        name: "LiffGroupBuyingOrdersCanceled",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/Orders/Canceled.vue"
          ),
        meta: { boundOnly: true },
      },
      // Orders V2 - 取得 orders 而非 groupbuying_orders 版本的分裂變異版...
      {
        path: "get-orders",
        name: "LiffGroupBuyingOrdersV2",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/OrdersV2/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "get-orders/:status(processing|completed|invalid)",
        name: "LiffGroupBuyingOrdersV2Status",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/OrdersV2/Index.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "get-orders/:status/detail/:id",
        name: "LiffGroupBuyingOrdersV2Detail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/OrdersV2/Detail.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "get-orders/edit/:id/",
        name: "LiffGroupBuyingOrdersV2Edit",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/OrdersV2/Edit.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":id",
        name: "LiffGroupBuyingShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/Show.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":id/ordered",
        name: "LiffGroupBuyingOrdered",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/Ordered.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":id/blacklisted",
        name: "LiffGroupBuyingBlacklisted",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/Blacklisted.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":id/:status(closed|suspended|failed|not-found)",
        name: "LiffGroupBuyingStatus",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/Status.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: "goto-group/:id",
        name: "LiffGroupBuyingGotoGroup",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GotoGroup.vue"
          ),
      },
      {
        path: ":id/branch-ordered/:order_id",
        name: "LiffGroupBuyingBranchOrderCompleted",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/BranchOrdered.vue"
          ),
        meta: { boundOnly: true },
      },
      {
        path: ":id/branch-canceled/:order_id",
        name: "LiffGroupBuyingBranchOrderCanceled",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'LiffGroupBuying' */ "@/pages/Liff/GroupBuying/GroupBuying/BranchCanceled.vue"
          ),
        meta: { boundOnly: true },
      },
    ],
  },

  /**
   * Point
   */
  {
    path: "/:org_code/liff/point/center",
    name: "LiffPointsCenter",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['point_center'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsCenter.vue"
      ),
  },
  {
    path: "/:org_code/liff/point",
    name: "LiffPoint",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_point'],
    },
    alias: "/:org_code/liff/points",
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/Layout"),
    children: [

      {
        path: "exchange",
        name: "LiffPointsExchange",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsExchange.vue"
          ),
      },
      {
        path: "exchange_records",
        name: "LiffPointsExchangeRecords",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsExchangeRecords.vue"
          ),
      },
      {
        path: "records",
        name: "LiffPointsRechargeRecords",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsRechargeRecords.vue"
          ),
      },
      {
        path: "transfer",
        name: "LiffPointsTransfer",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsTransfer.vue"
          ),
      },
    ],
  },
  {
    path: "/:org_code/liff/point/tab",
    name: "LiffPoint",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_point'],
    },
    alias:"/:org_code/liff/points/tab",
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/Layout"),
    children: [

      {
        path: "exchange",
        name: "LiffPointsExchangeTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsExchange.vue"
          ),
      },
      {
        path: "exchange_records",
        name: "LiffPointsExchangeRecordsTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsExchangeRecords.vue"
          ),
      },
      {
        path: "records",
        name: "LiffPointsRechargeRecordsTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsRechargeRecords.vue"
          ),
      },
      {
        path: "transfer",
        name: "LiffPointsTransferTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Point/PointsTransfer.vue"
          ),
      },
    ],
  },
  /**
   * Voucher list
   */
  {
    path: "/:org_code/liff/credit",
    name: "LiffVoucher",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_credit'],
    },
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Voucher/Layout"),
    children: [
      {
        path: "",
        name: "LiffVoucherCenter",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Voucher/VoucherCenter.vue"
          ),
      },
    ],
  },

  /**
   * Coupon V2
   */
  {
    path: "/:orgCode/liff/coupon",
    name: "LiffCouponList",
    //redirect: { name: "LiffCouponMy" },
    component: () =>
      import(/*webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/Layout"),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_coupon'],
      extraModules: ['member_center']
    },
    children: [
      {
        path: "records",
        name: "LiffCouponListRecords",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponRecords.vue"
          ),
      },
      {
        path: "select_item",
        name: "LiffCouponListSelectItem",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponSelectItem.vue"
          ),
      },
      {
        path: "select_branch",
        name: "LiffCouponListSelectBranch",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponSelectBranch.vue"
          ),
      },
      {
        path: "redeem_confirm",
        name: "LiffCouponListRedeemConfirm",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponRedeemConfirm.vue"
          ),
      },
      {
        path: "redeem_success",
        name: "LiffCouponListRedeemSuccess",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponRedeemSuccess.vue"
          ),
      },
      {
        path: "my",
        name: "LiffCouponListMy",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponMy.vue"
          ),
      },
      {
        path: "apply",
        name: "LiffCouponListHome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponHome.vue"
          ),
      },
    ]
  },
  {
    path: "/:orgCode/liff/coupon/tab",
    name: "LiffCouponList",
    //redirect: { name: "LiffCouponMy" },
    component: () =>
      import(/*webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/Layout"),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_coupon']
    },
    children: [
      {
        path: "my",
        name: "LiffCouponMyTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponMy.vue"
          ),
      },
      {
        path: "apply",
        name: "LiffCouponHomeTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponHome.vue"
          ),
      },
      {
        path: "records",
        name: "LiffCouponRecordsTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/CouponList/CouponRecords.vue"
          ),
      },
    ]
  },

  /**
   * Coupon
   */
  {
    path: "/:orgCode/liff/coupon",
    name: "LiffCoupon",
    //redirect: { name: "LiffCouponMy" },
    component: () =>
      import(/*webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/Coupon/Layout"),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_coupon']
    },
    children: [
      {
        path: "bundle/:id",
        name: "LiffCouponBundle",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/Coupon/CouponBundle.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffCouponShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/Coupon/CouponShow.vue"
          ),
      },
      {
        path: "auto_apply/:id",
        name: "LiffCouponShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCoupon' */ "@/pages/Liff/Coupon/AutoApply.vue"
          ),
      },
    ],
  },

  /**
   * Mgm
   */
  {
    path: "/:orgCode/liff/mgm",
    name: "LiffMgm",
    component: () =>
      import(/*webpackChunkName: 'LiffMgm' */ "@/pages/Liff/Mgm/Layout"),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    children: [
      {
        path: ":mgmId/",
        name: "LiffMgmHome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMgm' */ "@/pages/Liff/Mgm/MgmHome.vue"
          ),
      },
      {
        path: ":mgmId/awards",
        name: "LiffMgmAwards",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMgm' */ "@/pages/Liff/Mgm/MgmAwards.vue"
          ),
      },
      {
        path: ":mgmId/intro",
        name: "LiffMgmIntro",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMgm' */ "@/pages/Liff/Mgm/MgmIntro.vue"
          ),
      },
      {
        path: ":mgmId/invite-records",
        name: "LiffMgmInviteRecords",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMgm' */ "@/pages/Liff/Mgm/MgmInviteRecords.vue"
          ),
      },
    ],
  },

  /**
   * 查發票
   */
  {
    path: "/:orgCode/liff/invoice",
    name: "LiffEinvoice",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Einvoice/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_invoice'],
    },
    children: [
      {
        path: "scan",
        name: "EinvoiceScan",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Scanner.vue"
          ),
      },
      {
        path: "traditional",
        name: "EinvoiceTraditional",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Traditional.vue"
          ),
      },
      {
        path: "einvoice",
        name: "EinvoiceEinvoice",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Einvoice.vue"
          ),
      },
      {
        path: "carrier",
        name: "EinvoiceCarrier",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Carrier.vue"
          ),
      },
      {
        path: "list",
        name: "EinvoiceList",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/List.vue"
          ),
      },
      {
        path: "carrier_invoice",
        name: "EinvoiceCarrierWithInvoice",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/CarrierInvoice.vue"
          ),
      },
    ],
  },

  /* 發票獨立 tab */
  {
    path: "/:orgCode/liff/invoice/tab",
    name: "LiffEinvoice",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Einvoice/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_invoice'],
    },
    children: [
      {
        path: "scan",
        name: "EinvoiceScanTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Scanner.vue"
          ),
      },
      {
        path: "traditional",
        name: "EinvoiceTraditionalTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Traditional.vue"
          ),
      },
      {
        path: "einvoice",
        name: "EinvoiceEinvoiceTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Einvoice.vue"
          ),
      },
      {
        path: "carrier",
        name: "EinvoiceCarrierTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/Carrier.vue"
          ),
      },
      {
        path: "list",
        name: "EinvoiceListTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/List.vue"
          ),
      },
      {
        path: "carrier_invoice",
        name: "EinvoiceCarrierWithInvoiceTab",
        component: () =>
          import(
            "@/pages/Liff/Einvoice/CarrierInvoice.vue"
          ),
      },
    ],
  },


  /**
   * Redeem 集點
   */
  {
    path: "/:orgCode/liff/redeem/:redeem_code?",
    name: "LiffRedeem",
    meta: {
      middleware: [liffRedeemCode, liffGeneralStoreInit, loadModules],
      modules: ['liff_redeem'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffRedeem' */ "@/pages/Liff/Redeem/RedeemHome.vue"
      ),
  },
  /**
   * 會員中心
   */
  {
    path: "/:orgCode/liff/member-center",
    name: "LiffMemberCenter",
    component: () =>
      import(
        /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
    },
    children: [
      {
        path: "/",
        name: "LiffMemberCenterHome",
        meta: {
          modules: ['member_center', 'member_register'],
          middleware: [checkPermission],
        },
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/MemberCenterHome.vue"
          ),
      },
      {
        path: "edit",
        name: "LiffMemberCenterEdit",
        meta: {
          modules: ['member_edit'],
          middleware: [checkPermission],
        },
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/MemberCenterEdit.vue"
          ),
      },
      {
        path: "edit_done",
        name: "LiffMemberCenterEditDone",
        meta: {
          modules: ['member_edit'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/MemberCenterEditDone.vue"
          ),
      },
      {
        path: "unbind",
        name: "LiffMemberCenterUnbind",
        meta: {
          modules: ['member_center'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/MemberCenterUnbind.vue"
          ),
      },
      {
        path: "profile",
        name: "LiffMemberCenterProfile",
        meta: {
          modules: ['member_center'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCenter' */ "@/pages/Liff/MemberCenter/MemberCenterProfile.vue"
          ),
      }
    ],
  },

  {
    path: "/:orgCode/liff/register",
    name: "LiffRegister",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ["member_register"],
    },
    children: [
      {
        path: "",
        name: "LiffRegisterIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterIndex.vue"
          ),
      },
      {
        path: "terms",
        name: "LiffRegisterTerms",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterTerms.vue"
          ),
      },
      {
        path: "member",
        name: "LiffRegisterMember",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterMember.vue"
          ),
      },
      {
        path: "verify_form",
        name: "LiffRegisterVerifyForm",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterVerifyForm.vue"
          ),
      },
      {
        path: "validate",
        name: "LiffRegisterValidate",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterValidate.vue"
          ),
      },
      {
        path: "welcome",
        name: "LiffRegisterWelcome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterWelcome.vue"
          ),
      },
      {
        path: "thanks",
        name: "LiffRegisterThanks",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterThanks.vue"
          ),
      },
      {
        path: "info/bypass_remind",
        name: "LiffRegisterBypassRemind",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterBypassRemind.vue"
          ),
      },
      {
        path: "info/provider_error",
        name: "LiffRegisterProviderError",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterProviderError.vue"
          ),
      },
      {
        path: "info/overwrite_check",
        name: "LiffRegisterOverwriteCheck",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterOverwriteCheck.vue"
          ),
      },
      {
        path: "info/not_register",
        name: "LiffRegisterNotRegister",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterNotRegister.vue"
          ),
      },
      {
        path: "info/:type?",
        name: "LiffRegisterInfo",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterInfo.vue"
          ),
      },
      {
        path: "intro",
        name: "LiffRegisterIntro",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Register/RegisterIntro.vue"
          ),
      },
    ],
  },

  {
    path: "/:orgCode/liff/sales/staff",
    name: "LiffStaff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffStaffActive' */ "@/pages/Liff/Sales/Staff/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, loadModules, liffAuth],
      modules: ['staff_active'],
    },
    children: [
      {
        path: "active",
        name: "LiffStaffActive",
        component: () =>
          import(
            /* webpackChunkName: 'LiffStaffActive' */ "@/pages/Liff/Sales/Staff/StaffActive.vue"
          ),
      },
      {
        path: "validate",
        name: "LiffStaffValidate",
        component: () =>
          import(
            /* webpackChunkName: 'LiffStaffActive' */ "@/pages/Liff/Sales/Staff/StaffValidate.vue"
          ),
      },
      {
        path: "active_success",
        name: "LiffStaffActiveSuccess",
        component: () =>
          import(
            /* webpackChunkName: 'LiffStaffActive' */ "@/pages/Liff/Sales/Staff/StaffActiveSuccess.vue"
          )
      },
      {
        path: "edit",
        name: "LiffStaffEdit",
        component: () =>
          import(
            /* webpackChunkName: 'LiffStaffActive' */ "@/pages/Liff/Sales/Staff/StaffEdit.vue"
          ),
      },
    ]
  },
  {
    path: "/:orgCode/liff/module",
    name: "LiffRegister",
    component: () =>
      import(
        /* webpackChunkName: 'LiffModule' */ "@/pages/Liff/Register/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    children: [
      {
        path: "not-yet",
        name: "LiffModuleNotYet",
        component: () =>
          import(
            /* webpackChunkName: 'LiffModule' */ "@/pages/Liff/Module/NotYet.vue"
          ),
      },
    ],
  },

  /**
   * Member Card（寶雅 only）
   */
  {
    path: "/:orgCode/liff/membercard",
    name: "LiffMemberCardPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Layout.vue"
      ),
    meta: {
      middleware: [liffMemberCard],
    },
    children: [
      {
        path: "debug",
        name: "LiffMemberCardDebug",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Debug.vue"
          ),
      },
      {
        path: "auth/me",
        name: "LiffMemberCardAuthMe",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Auth/Me.vue"
          ),
      },
      {
        path: "transactions",
        name: "LiffMemberCardTransactionsIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Transactions/Index.vue"
          ),
      },
      {
        path: "transactions/:id",
        name: "LiffMemberCardTransactionsShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Transactions/Show.vue"
          ),
      },
      {
        path: "points",
        name: "LiffMemberCardPointsIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffMemberCard' */ "@/pages/Liff/MemberCard/Points.vue"
          ),
      },
    ],
  },

  /**
   * Chatroom
   */
  {
    path: "/:orgCode/liff/chatrooms-multiple-upload",
    component: () =>
      import(
        /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "LiffChatroomMultipleUpload",
        component: () =>
          import(
            /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/MultipleUpload.vue"
          ),
      },
    ],
  },
  {
    path: "/:orgCode/liff/chatrooms",
    name: "LiffChatroomPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/Layout.vue"
      ),
    meta: {
      middleware: [liffChatroom],
    },
    children: [
      {
        path: "",
        name: "LiffChatroomIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/Index.vue"
          ),
      },
      {
        path: "go-to-chat",
        name: "LiffChatroomGoToChat",
        component: () =>
          import(
            /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/GoToChat.vue"
          ),
      },
      {
        path: "go-to-chat-staff",
        name: "LiffChatroomGoToChatStaff",
        component: () =>
          import(
            /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/GoToChatStaff.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffChatroomShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffChatroom' */ "@/pages/Liff/Chatroom/Show.vue"
          ),
      },
    ],
  },

  /**
   * RoyalCanin
   */
  {
    path: "/:orgCode/liff/royalcanin",
    name: "LiffRoyalCanin",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRoyalCanin' */ "@/pages/Liff/RoyalCanin/Layout.vue"
      ),
    // meta: {
    //   middleware: [liffChatroom],
    // },
    children: [
      {
        path: "",
        name: "LiffRoyalCaninSurvey",
        component: () =>
          import(
            /* webpackChunkName: 'LiffRoyalCanin' */ "@/pages/Liff/RoyalCanin/Survey.vue"
          ),
      },
    ],
  },
  /**
   * CRM
   */
  {
    path: "/:orgCode/liff/crm",
    component: () =>
      import(
        /* webpackChunkName: 'LiffCrm' */ "@/pages/Liff/Crm/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ['member_bind'],
    },
    children: [
      {
        path: "staff-binding",
        name: "LiffCrmStaffBinding",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCrm' */ "@/pages/Liff/Crm/StaffBinding.vue"
          ),
      },
      {
        path: "customer-binding",
        name: "LiffCrmCustomerBinding",
        component: () =>
          import(
            /* webpackChunkName: 'LiffCrm' */ "@/pages/Liff/Crm/CustomerBinding.vue"
          ),
      },
    ],
  },
  {
    path: "/:orgCode/liff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSpecial' */ "@/pages/Liff/EmptyLayout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    children: [
      {
        path: "redirect",
        name: "LiffRedirect",
        component: () =>
          import(
            /* webpackChunkName: 'LiffSpecial' */ "@/pages/Liff/Redirect.vue"
          ),
      },
    ],
  },
  {
    path: "/:orgCode/liff/module-not-enabled",
    name: "ModuleNotEnabled",
    component: () =>
      import(
        /* webpackChunkName: 'Liff' */ "@/pages/Error/ModuleNotEnabled.vue"
      ),
  },
  {
    path: "/:orgCode/liff/old-version-redirect*",
    component: () =>
      import(
        /* webpackChunkName: 'LiffSpecial' */ "@/pages/Liff/OldVersionRedirect.vue"
      ),
  },



  /**
   * Kirin
   */
  {
    path: "/:orgCode/liff/kirin/badgemap/:event_code?",
    name: "LiffKirin",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
      import(
        "@/pages/Liff/Kirin/Badgemap.vue"
      ),
  },

  /**
   * Orders Module
   */
  {
    path: "/:orgCode/liff/order",
    name: "LiffOrderListPortal",
    component: () =>
      import(
        /* webpackChunkName: 'LiffOrderList' */ "@/pages/Liff/OrderList/Layout.vue"
      ),
    alias: "/:orgCode/liff/orders",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_order'],
    },
    children: [
      {
        path: "tab/:source",
        name: "LiffOrderListIndexTab",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrderList' */ "@/pages/Liff/OrderList/Index.vue"
          ),
      },
      {
        path: "list",
        name: "LiffOrderListIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrderList' */ "@/pages/Liff/OrderList/Index.vue"
          ),
      },
      {
        path: "list/list",
        redirect: to => {
          return { path: "list", query: { source: to.query.source } }
        }
      },
      {
        path: "list/:id",
        name: "LiffOrderListShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOrderList' */ "@/pages/Liff/OrderList/Show.vue"
          ),
      },
    ],
  },


  /**
   * Branch
   */
  {
    path: "/:org_code/liff/branch",
    name: "LiffBranch",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ['liff_branch'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffBranch' */ "@/pages/Liff/Branch/Layout.vue"
      ),
    children: [
      {
        path: "/",
        name: "LiffBranchIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffBranch' */ "@/pages/Liff/Branch/Index.vue"
          ),
      },
      {
        path: "branch_picker",
        name: "LiffBranchBranchPicker",
        component: () =>
          import(
            /* webpackChunkName: 'LiffBranchBranchPicker' */ "@/pages/Liff/Branch/BranchPicker.vue"
          ),
      },
    ],
  },

  /**
   * Rewards Card
   */
  {
    path: "/:org_code/liff/rewards-card",
    name: "LiffRewardsCard",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_rewards_card'],
    },
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/Layout.vue"),
    children: [
      {
        path: "/",
        name: "BaseLiffRewardsCardHome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardHome.vue"
          ),
      },
      {
        path: "collect",
        name: "LiffRewardsCardCollect",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardCollect.vue"
          ),
      },
      {
        path: "card_list",
        name: "LiffRewardsCardList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardList.vue"
          ),
      },
      {
        path: "card_detail",
        name: "LiffRewardsCardDetail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardDetail.vue"
          ),
      },
      {
        path: "card_receiver",
        name: "LiffRewardsCardReceiver",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardReceiver.vue"
          ),
      },
      {
        path: "mission/:mission_id",
        name: "LiffRewardsMission",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsMission.vue"
          ),
      },
      {
        path: ":rewardsCardId",
        name: "LiffRewardsCardHome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/RewardsCard/RewardsCardHome.vue"
          ),
      },
    ],
  },
  /**
   * Branch
   */
  {
    path: "/:org_code/liff/",
    name: "LiffShareProduct",
    meta: {
      // middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffShare' */ "@/pages/Liff/Share/Product.vue"
      ),

  },
  /**
   * Games
   */
  {
    path: "/:org_code/liff/games",
    name: "LiffGames",
    meta: {
      middleware: [liffGeneralStoreInit],
    },
    component: () =>
      import(/*webpackChunkName: 'LiffGame' */ "@/pages/Liff/Games/Layout.vue"),
    children: [
      {
        path: "scratch",
        name: "LiffGamesScratch",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGame' */ "@/pages/Liff/Games/Scratch.vue"
          ),
      },
      {
        path: "photo-frame/:event_code?",
        name: "LiffGamesPhotoFrame",
        component: () =>
          import(
            /* webpackChunkName: 'LiffGame' */ "@/pages/Liff/Games/PhotoFrame.vue"
          ),
      }
    ],
  },

  /**
   * Badge
   */
  {
    path: "/:org_code/liff/badge",
    name: "LiffBadge",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_badge']
    },
    redirect: { name: "LiffBadgeEventList" },
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Badge/Layout.vue"),
    children: [
      {
        path: "overall",
        name: "LiffBadgeEventList",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Badge/BadgeOverall.vue"
          ),
      },
      {
        path: ":event_id/detail/:badge_id",
        name: "LiffBadgeDetail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Badge/BadgeOverall.vue"
          ),
      },
      {
        path: ":event_id",
        name: "LiffBadgeEventDetail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Badge/BadgeOverall.vue"
          ),
      },
    ],
  },

  /**
   * Checkin
   */
  {
    path: "/:org_code/liff/checkin",
    name: "LiffCheckin",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission, ],
      modules: ['liff_checkin']
    },
    redirect: { name: "LiffCheckinIndex" },
    component: () =>
      import(/*webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Checkin/Layout.vue"),
    children: [
      {
        path: "",
        name: "LiffCheckinIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffPoint' */ "@/pages/Liff/Checkin/CheckinIndex.vue"
          ),
      },
    ],
  },

  /** Worker */
  {
    path: "/:orgCode/liff/worker",
    name: "LiffWorker",
    component: () =>
      import(
        /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ["worker_active"],
    },
    children: [
      {
        path: "",
        name: "LiffWorkerIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerIndex.vue"
          ),
      },
      {
        path: "terms",
        name: "LiffWorkerTerms",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerTerms.vue"
          ),
      },
      {
        path: "active",
        name: "LiffWorkerActive",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerActive.vue"
          ),
      },
      {
        path: "confirm",
        name: "LiffWorkerConfirm",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerConfirm.vue"
          ),
      },
      {
        path: "validate",
        name: "LiffWorkerValidate",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerValidate.vue"
          ),
      },
      {
        path: "welcome",
        name: "LiffWorkerWelcome",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerWelcome.vue"
          ),
      },
      {
        path: "worker_staff",
        name: "LiffWorkerStaffInfo",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerStaffInfo.vue"
          ),
      },
      {
        path: "pending",
        name: "LiffWorkerPending",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerPending.vue"
          ),
      },
      {
        path: "thanks",
        name: "LiffWorkerThanks",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerThanks.vue"
          ),
      },
      {
        path: "failed",
        name: "LiffWorkerFailed",
        component: () =>
          import(
            /* webpackChunkName: 'LiffWorker' */ "@/pages/Liff/Worker/WorkerFailed.vue"
          ),
      },
    ],
  },

   /*
  * Shop
  */
   {
    path: "/:orgCode/liff/shop",
    name: "LiffShop",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
      import(
        "@/pages/Liff/Shop/Layout.vue"
      ),
      children: [
        {
          path: "",
          name: "LiffShopProducts",
          component: () =>
            import(
              /* webpackChunkName: '' */ "@/pages/Liff/Shop/Products.vue"
            ),
        },
        {
          path: "product/:id",
          name: "LiffShopProduct",
          component: () =>
            import(
              /* webpackChunkName: '' */ "@/pages/Liff/Shop/Product.vue"
            ),
        },
        {
          path: "cart",
          name: "LiffShopCart",
          component: () =>
            import(
              /* webpackChunkName: '' */ "@/pages/Liff/Shop/Cart.vue"
            ),
        },
        {
          path: "checkout",
          name: "LiffShopCheckout",
          component: () =>
            import(
              /* webpackChunkName: '' */ "@/pages/Liff/Shop/Checkout.vue"
            ),
        },
        {
          path: "finished",
          name: "LiffShopFinished",
          component: () =>
            import(
              /* webpackChunkName: '' */ "@/pages/Liff/Shop/Finished.vue"
            ),
        },
      ],
  },

  /**
   * Auto Generator Page Module
   */
  {
    path: "/:orgCode/liff/page/:autoPage",
    component: () =>
    import(
      /* webpackChunkName: 'LiffCustomerBind' */ "@/pages/Liff/AutoPage/Layout.vue"
    ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_page'],
    },
    children: [
      {
        path: "",
        name: "LiffAutoPageIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffNews' */ "@/pages/Liff/AutoPage/Index.vue"
          ),
      },
    ]
  },

  /**
   * News Module
   */
  {
    path: "/:orgCode/liff/news",
    name: "LiffNews",
    redirect: { name: "LiffNewsIndex" },
    component: () =>
      import(
        /* webpackChunkName: 'LiffOrderList' */ "@/pages/Liff/News/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_news'],
    },
    children: [
      {
        path: "",
        name: "LiffNewsIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffNews' */ "@/pages/Liff/News/Index.vue"
          ),
      },
      {
        path: "list/:id",
        name: "LiffNewsShow",
        component: () =>
          import(
            /* webpackChunkName: 'LiffNews' */ "@/pages/Liff/News/Show.vue"
          ),
      },
    ],
  },

  /**
   * Form 表單頁面
   */
  {
    path: "/:orgCode/liff/form",
    name: "LiffForm",
    component: () =>
      import(
        /* webpackChunkName: 'LiffForm' */ "@/pages/Liff/Form/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, loadModules, liffAuth],
      modules: ['liff_form'],
    },
    children: [
      {
        path: ":form_id?",
        name: "LiffFormIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffForm' */ "@/pages/Liff/Form/Index.vue"
          ),
      },
      {
        path: ":form_id/content",
        name: "LiffFormContent",
        component: () =>
          import(
            /* webpackChunkName: 'LiffForm' */ "@/pages/Liff/Form/Content.vue"
          ),
      },
      {
        path: ":form_id/thanks",
        name: "LiffFormThanks",
        component: () =>
          import(
            /* webpackChunkName: 'LiffForm' */ "@/pages/Liff/Form/Thanks.vue"
          ),
      },
    ]
  },

  /**
   * 預約制度
   */
  {
    path: "/:orgCode/liff/booking",
    name: "LiffBooking",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Booking/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_booking'],
    },
    children: [
      {
        path: "scheduled",
        name: "BookingScheduled",
        component: () =>
          import(
            "@/pages/Liff/Booking/Scheduled.vue"
          ),
      },
      {
        path: "completed",
        name: "BookingCompleted",
        component: () =>
          import(
            "@/pages/Liff/Booking/Completed.vue"
          ),
      },
      {
        path: "cancelled",
        name: "BookingCancelled",
        component: () =>
          import(
            "@/pages/Liff/Booking/Cancelled.vue"
          ),
      },
    ],
  },

  /**
   * 預約表單
   */
  {
    path: "/:orgCode/liff/booking/form/:bookingPresetId",
    name: "BookingForm",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Booking/Form.vue"
      ),
    // meta: {
    //   middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
    //   modules: ['liff_booking'],
    // },
  },


  /**
   * Reservation
   */
  {
    path: "/:orgCode/liff/reservation",
    name: "LiffReservation",
    component: () =>
      import(
        /* webpackChunkName: 'LiffReservation' */ "@/pages/Liff/Reservation/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, loadModules],
      modules: ['liff_reservation'],
    },
    children: [
      {
        path: "form",
        name: "LiffReservationForm",
        component: () =>
          import(
            /* webpackChunkName: 'LiffReservationForm' */ "@/pages/Liff/Reservation/ReservationForm.vue"
          )
      }
    ]
  },

  /**
   * Proj Section
   * 一些特殊客制化的頁面
   */
  {
    path: "/:orgCode/liff/proj/kirin/:redeem_code?",
    name: "LiffProjKirin",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ['liff_redeem'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjKirin' */ "@/pages/Liff/Proj/Kirin.vue"
      ),
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/rewards-card",
    name: "LiffProjAbbottAndRewardCard",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_rewards_card'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbottAndRewardsCard' */ "@/pages/Liff/Proj/AbbottAnd/RewardsCard/Layout.vue"
      ),
    children: [
      {
        path: "card_list",
        name: "LiffProjAbbottAndRewardsCardList",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndRewardsCard' */ "@/pages/Liff/Proj/AbbottAnd/RewardsCard/RewardsCardList.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffProjAbbottAndRewardsCardDetail",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndRewardsCard' */ "@/pages/Liff/Proj/AbbottAnd/RewardsCard/RewardsCardDetail.vue"
          ),
      },
      {
        path: ":id/card_receiver",
        name: "LiffProjAbbottAndRewardsCardRedeem",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndRewardsCard' */ "@/pages/Liff/Proj/AbbottAnd/RewardsCard/RewardsCardReceiver.vue"
          ),
      },
    ]
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/coupon",
    name: "LiffProjAbbottAndCoupon",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_coupon'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/Layout.vue"
      ),
    children: [
      {
        path: "apply",
        name: "LiffProjAbbottAndCouponApply",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/ApplyCoupon.vue"
          ),
      },
      {
        path: "my",
        name: "LiffProjAbbottAndCouponMy",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/MyCoupon.vue"
          ),
      },
      {
        path: "records",
        name: "LiffProjAbbottAndCouponRecords",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/RecordsCoupon.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffProjAbbottAndCouponDetail",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/CouponDetail.vue"
          ),
      },
      {
        path: ":id/select_item",
        name: "LiffProjAbbottAndCouponSelectItem",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/CouponSelectItem.vue"
          ),
      },
      {
        path: ":id/redeem_confirm",
        name: "LiffProjAbbottAndCouponRedeemConfirm",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/CouponRedeemConfirm.vue"
          ),
      },
      {
        path: ":id/redeem_success",
        name: "LiffProjAbbottAndCouponRedeemSuccess",
        meta: {},
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjAbbottAndCoupon' */ "@/pages/Liff/Proj/AbbottAnd/CouponList/CouponRedeemSuccess.vue"
          ),
      }
    ]
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/branch",
    name: "LiffProjAbbottAndBranch",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_branch'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbott' */ "@/pages/Liff/Proj/AbbottAnd/Branch/Layout.vue"
      ),
    children: [
      {
        path: "/:orgCode/liff/proj/abbott-and/branch/branch_picker",
        name: "LiffProjAbbottAndBranchPicker",
        component: () =>
          import(
          /* webpackChunkName: 'LiffProjAbbott' */ "@/pages/Liff/Proj/AbbottAnd/Branch/BranchPicker.vue"
          ),
      }
    ]
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/thanks",
    name: "LiffProjAbbottAndThanks",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ['liff_coupon'],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbott' */ "@/pages/Liff/Proj/AbbottAnd/Thanks.vue"
      ),
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/:couponType",
    name: "LiffProjAbbottAndRecordQuery",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbott' */ "@/pages/Liff/Proj/AbbottAnd/RecordQuery.vue"
      ),
  },
  {
    path: "/:orgCode/liff/proj/abbott-and/:couponType/sendCoupon",
    name: "LiffProjAbbottAndSendCoupon",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth],
    },
    component: () =>
      import(
        /* webpackChunkName: 'LiffProjAbbott' */ "@/pages/Liff/Proj/AbbottAnd/SendCoupon.vue"
      ),
  },

  /**
   * 倩碧 Clinique 客製化專案
   */
  {
    path: "/:orgCode/liff/proj/clinique",
    name: "LiffProjClinique",
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_redeem'],
    },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "LiffProjCliniqueIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjClinique' */ "@/pages/Liff/Proj/Clinique/Index.vue"
          ),
      },
      {
        path: "code/:redeem_code_hashid",
        name: "LiffProjCliniqueCode",
        component: () =>
          import(
            /* webpackChunkName: 'LiffProjClinique' */ "@/pages/Liff/Proj/Clinique/Code.vue"
          ),
      },
    ]
  },

  /**
   * 業務 module
   */
  {
    path: "/:orgCode/liff/staff",
    name: "LiffStaff",
    component: () =>
      import(
        /* webpackChunkName: 'LiffRegister' */ "@/pages/Liff/Staff/Layout.vue"
      ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules, checkPermission],
      modules: ['liff_staff'],
    },
    children: [
      {
        path: "staff-list",
        name: "LiffStaffList",
        component: () =>
          import(
            "@/pages/Liff/Staff/StaffList.vue"
          ),
      },
      {
        path: "staff-bind-success",
        name: "LiffStaffBindSuccess",
        component: () =>
          import(
            "@/pages/Liff/Staff/StaffBindSuccess.vue"
          ),
      },
    ],
  },

  /**
   * Auto Generator Outer Page Module
   */
  {
    path: "/:orgCode/liff/outer/:source",
    component: () =>
    import(
      /* webpackChunkName: 'LiffOuter' */ "@/pages/Liff/Outer/Layout.vue"
    ),
    meta: {
      middleware: [liffGeneralStoreInit, liffAuth, loadModules],
      modules: ['liff_outer'],
    },
    children: [
      {
        path: "",
        name: "LiffOuterIndex",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOuter' */ "@/pages/Liff/Outer/Index.vue"
          ),
      },
      {
        path: ":id",
        name: "LiffOuterDetail",
        component: () =>
          import(
            /* webpackChunkName: 'LiffOuter' */ "@/pages/Liff/Outer/Show.vue"
          ),
      },
    ]
  },
];
