export const ROLE_SYS_ADMIN = "sys-admin"; // 系統管理員
export const ROLE_ADMIN = "admin"; // 組織管理員
export const ROLE_STAFF = "staff"; // 組織員工

export const ORGANIZATION_VARIABLE = "ORGANIZATION_VARIABLE";
export const ORGANIZATION_VARIABLE_CREATE = "ORGANIZATION_VARIABLE_CREATE"; // 組織參數新增
export const ORGANIZATION_VARIABLE_MODIFY = "ORGANIZATION_VARIABLE_MODIFY"; // 組織參數修改刪除
export const ORGANIZATION_MERCHANT_VARIABLE = "ORGANIZATION_MERCHANT_VARIABLE";
export const ORGANIZATION_MERCHANT_TEMPLATE = "ORGANIZATION_MERCHANT_TEMPLATE";
export const ORGANIZATION_MERCHANT_VARIABLE_CREATE =
  "ORGANIZATION_MERCHANT_VARIABLE_CREATE"; // 通路參數新增
export const ORGANIZATION_MERCHANT_VARIABLE_MODIFY =
  "ORGANIZATION_MERCHANT_VARIABLE_MODIFY"; // 通路參數修改刪除
export const ORGANIZATION_LIST_VIEW = "ORGANIZATION_LIST_VIEW";
export const ORGANIZATION_MODIFY = "ORGANIZATION_MODIFY";
export const ORGANIZATION_DELETE = "ORGANIZATION_DELETE";

export const MERCHANT_LIST_VIEW = "MERCHANT_LIST_VIEW";
export const MERCHANT_MODIFY = "MERCHANT_MODIFY";
export const MERCHANT_DELETE = "MERCHANT_DELETE";

export const STAFF_LIST_VIEW = "STAFF_LIST_VIEW"; // 帳號清單、查看帳號內容
export const STAFF_LIST_VIEW_ALL_BRANCH = "STAFF_LIST_VIEW-ALL_BRANCH"; // 可以看所有分店的帳號
export const STAFF_LIST_VIEW_NO_BRANCH = "STAFF_LIST_VIEW-NO_BRANCH"; // 可以看沒有 branch 的帳號
export const STAFF_LIST_VIEW_SAME_LEVEL = "STAFF_LIST_VIEW-SAME_LEVEL"; // 可以看權重與自己同等級的角色
export const STAFF_MODIFY = "STAFF_MODIFY"; // 新增、修改、批次上傳帳號
export const STAFF_BRANCH_MODIFY = "STAFF_BRANCH_MODIFY"; // 設定帳號分店
export const STAFF_ROLE_MODIFY = "STAFF_ROLE_MODIFY"; // 設定帳號角色
export const STAFF_DELETE = "STAFF_DELETE"; // 刪除帳號
export const STAFF_CHANGE_PASSWORD = "STAFF_CHANGE_PASSWORD"; // 變更帳號密碼
export const STAFF_CUSTOMER_VIEW = "STAFF_CUSTOMER_VIEW"; // 客戶綁定清單

export const GROUP_LIST_VIEW_1 = "GROUP_LIST_VIEW_1"; // 群組列表(狀態：正常)
export const GROUP_LIST_VIEW_2 = "GROUP_LIST_VIEW_2"; // 群組列表(狀態：未完成)
export const GROUP_LIST_VIEW_3 = "GROUP_LIST_VIEW_3"; // 群組列表(狀態：已退出)

export const GROUP_VIEW_DETAIL = "GROUP_VIEW_DETAIL"; // 動作 A：查看七天內對話紀錄、查看群組用戶名單
export const GROUP_EDIT_LINK = "GROUP_EDIT_LINK"; // 動作 B：編輯群組連結
export const GROUP_DELETE_LOGS = "GROUP_DELETE_LOGS"; // 動作 C：刪除群組紀錄
export const GROUP_EDIT_BRANCH = "GROUP_EDIT_BRANCH"; // 動作 D：編輯所屬分店
export const GROUP_LEAVE = "GROUP_LEAVE"; // 動作 E：機器人退出群組

export const GUARD_GROUP_LIST_VIEW = "GUARD_GROUP_LIST_VIEW"; // 衛兵群組列表
export const GUARD_GROUP_MODIFY = "GUARD_GROUP_MODIFY"; // 衛兵群組列表設定(設定、重設 OA 群組 黑名單)
export const GUARD_GROUP_SETTING_LIST_VIEW = "GUARD_GROUP_SETTING_LIST_VIEW"; // 衛兵群組保護設定（檢視）
export const GUARD_GROUP_SETTING_MODIFY = "GUARD_GROUP_SETTING_MODIFY"; // 衛兵群組保護設定（變更）
export const GUARD_MEMBER_LIST_VIEW = "GUARD_MEMBER_LIST_VIEW"; // 衛兵群組成員名單設定（檢視）
export const GUARD_BLACK_GLOBAL_MODIFY = "GUARD_BLACK_GLOBAL_MODIFY"; // 衛兵群組廣告黑名單設定（變更）
export const GUARD_MEMBER_MODIFY = "GUARD_MEMBER_MODIFY"; // 衛兵群組成員名單設定（變更）
export const GUARD_REPORT_LIST_VIEW = "GUARD_REPORT_LIST_VIEW"; // 衛兵狀態列表
export const GUARD_BELONG_LIST_VIEW = "GUARD_BELONG_LIST_VIEW"; // 衛兵所屬群組

export const NOTIFICATION_LIST = "NOTIFICATION_LIST"; // 通知管理(列表)
export const NOTIFICATION_MODIFY = "NOTIFICATION_MODIFY"; // 新增修改刪除通知
export const NOTIFICATION_MODIFY_CHANGE_BRANCH =
  "NOTIFICATION_MODIFY_CHANGE_BRANCH"; // 通知管理-選擇分店

export const CORE_GROUPBUYING_LIST_VIEW = "CORE_GROUPBUYING_LIST_VIEW"; // CORE-團購列表
export const CORE_GROUPBUYING_MODIFY = "CORE_GROUPBUYING_MODIFY"; // CORE-團購修改、刪除
export const CORE_GROUPBUYING_CREATE = "CORE_GROUPBUYING_CREATE"; // CORE-團購新增
export const CORE_GROUPBUYING_STATUS_MODIFY = "CORE_GROUPBUYING_STATUS_MODIFY"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_DRAFT = "CORE_GROUPBUYING_LIST_DRAFT"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_WAITING = "CORE_GROUPBUYING_LIST_WAITING"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_GOING = "CORE_GROUPBUYING_LIST_GOING"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_CONFIRMED =
  "CORE_GROUPBUYING_LIST_CONFIRMED"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_FINISHED = "CORE_GROUPBUYING_LIST_FINISHED"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_PENDING = "CORE_GROUPBUYING_LIST_PENDING"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_CANCELED = "CORE_GROUPBUYING_LIST_CANCELED"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_ARCHIVED = "CORE_GROUPBUYING_LIST_ARCHIVED"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）
export const CORE_GROUPBUYING_LIST_DELETED = "CORE_GROUPBUYING_LIST_DELETED"; // CORE-團購狀態設定（結單、流團、暫停收單、完成團購）

export const CORE_GROUPBUYING_PRODUCT_LIST = "CORE_GROUPBUYING_PRODUCT_LIST"; // CORE-團購列表
export const CORE_GROUPBUYING_PRODUCT_MODIFY =
  "CORE_GROUPBUYING_PRODUCT_MODIFY"; // CORE-團購商品修改
export const CORE_GROUPBUYING_PRODUCT_CREATE =
  "CORE_GROUPBUYING_PRODUCT_CREATE"; // CORE-團購商品新增

export const POYA_GROUPBUYINGORDER_SEARCH = "POYA_GROUPBUYINGORDER_SEARCH"; // POYA 顧客訂單查詢
export const GROUPBUYING_LIST_VIEW = "GROUPBUYING_LIST_VIEW"; // 團購列表
export const GROUPBUYING_MODIFY = "GROUPBUYING_MODIFY"; // 我要開團（新增、修改、刪除團購）
export const GROUPBUYING_STATUS_MODIFY = "GROUPBUYING_STATUS_MODIFY"; // 團購狀態設定（結單、流團、暫停收單、完成團購）
export const GROUPBUYING_BLACKLIST = "GROUPBUYING_BLACKLIST"; // 逾期未取名單
export const STORE_GROUPBUYING_CREATE = "STORE_GROUPBUYING_CREATE"; // 門市庫存品開團
export const STORE_GROUPBUYING_DRAFT = "STORE_GROUPBUYING_DRAFT"; // 門市庫存品-草稿
export const GROUPBUYING_BLACKLIST_REVOKE = "GROUPBUYING_BLACKLIST_REVOKE"; // 解除停權（黑名單）
export const GROUPBUYING_SKU_QTY_UPDATE = "GROUPBUYING_SKU_QTY_UPDATE"; // 購買倍數設定
export const GROUPBUYING_ORDER_QTY_UPDATE = "GROUPBUYING_ORDER_QTY_UPDATE"; // 調整團購單數量
export const GROUPBUYING_ORDER_CANCEL = "GROUPBUYING_ORDER_CANCEL"; // 取消團購單

export const MAIN_STORE_GROUPBUYING_CREATE = "MAIN_STORE_GROUPBUYING_CREATE"; // 總公司庫存品團購新增
export const MAIN_GROUPBUYING_LIST_VIEW = "MAIN_GROUPBUYING_LIST_VIEW"; // 總公司團購列表
export const MAIN_GROUPBUYING_CREATE = "MAIN_GROUPBUYING_CREATE"; // 總公司團購新增
export const MAIN_GROUPBUYING_MODIFY = "MAIN_GROUPBUYING_MODIFY"; // 總公司團購編輯、刪除
export const SELECTION_GROUPBUYING_CREATE = "SELECTION_GROUPBUYING_CREATE"; // 總公司選品建立

export const CUSTOMER_LIST_VIEW = "CUSTOMER_LIST_VIEW"; // 會員列表（查看會員內容）
export const CUSTOMER_UNBINB = "CUSTOMER_UNBINB"; // 解除綁定
export const CUSTOMER_STAFF_VIEW = "CUSTOMER_STAFF_VIEW"; // 業務綁定清單
export const CUSTOMER_LIST_IMPORT = "CUSTOMER_LIST_IMPORT"; // 顧客資料上傳
export const CUSTOMER_LIST_EDIT = "CUSTOMER_LIST_EDIT"; // 編輯個人資料與擴充欄位
export const CUSTOMER_VIEW = "CUSTOMER_VIEW"; // 會員檢視
export const CUSTOMER_EDIT_UNBIND = "CUSTOMER_EDIT_UNBIND"; // 會員編輯-解除綁定
export const CUSTOMER_EDIT_RESET = "CUSTOMER_EDIT_RESET"; // 會員編輯-帳號重設
export const CUSTOMER_EDIT_UNREGISTER = "CUSTOMER_EDIT_UNREGISTER"; // 會員編輯-重新註冊

export const BRANCH_LIST_VIEW = "BRANCH_LIST_VIEW"; // 分店列表（含下層）
export const BRANCH_MODIFY = "BRANCH_MODIFY"; // 新增修改分店
export const BRANCH_DELETE = "BRANCH_DELETE"; // 刪除分店
export const BRANCH_DETAIL_MODIFY_STAFF = "BRANCH_DETAIL_MODIFY_STAFF"; // （店內）新增、修改、批次上傳帳號
export const BRANCH_DETAIL_DELETE_STAFF = "BRANCH_DETAIL_DELETE_STAFF"; // （店內）刪除帳號
export const BRANCH_DETAIL_CHANGE_PASSWORD = "BRANCH_DETAIL_CHANGE_PASSWORD"; // （店內）變更帳號密碼
export const BRANCH_TOP_CREATE = "BRANCH_TOP_CREATE"; // 新增最上層分店
export const BRANCH_TOP_VIEW = "BRANCH_TOP_VIEW"; // 顯示最上層分店

export const ORDER_LIST_VIEW = "ORDER_LIST_VIEW"; // 訂單查看
export const ORDER_CREATE = "ORDER_CREATE"; // 新增訂單
export const ORDER_MODIFY = "ORDER_MODIFY"; // 修改查看
export const ORDER_DELETE = "ORDER_DELETE"; // 刪除訂單

export const OA_MESSAGE_VIEW = "OA_MESSAGE_VIEW"; // OA 訊息管理
export const OA_PUSH_VIEW = "OA_PUSH_VIEW"; // OA 群發訊息

export const LINE_MENU_LIST_VIEW = "LINE_MENU_LIST_VIEW"; // 圖文訊息列表
export const LINE_MENU_CREATE = "LINE_MENU_CREATE"; // 圖文訊息新增
export const LINE_MENU_MODIFY = "LINE_MENU_MODIFY"; // 圖文訊息修改
export const LINE_MENU_DELETE = "LINE_MENU_DELETE"; // 圖文訊息刪除

export const PRODUCT_LIST_VIEW = "PRODUCT_LIST_VIEW"; // 商品查看
export const PRODUCT_CREATE = "PRODUCT_CREATE"; // 新增商品
export const PRODUCT_MODIFY = "PRODUCT_MODIFY"; // 修改商品
export const PRODUCT_DELETE = "PRODUCT_DELETE"; // 刪除商品
export const BRANCH_PRODUCT_SYNC = "BRANCH_PRODUCT_SYNC"; // 同步所有分店商品
export const PRODUCT_IMAGE_MODIFY = "PRODUCT_IMAGE_MODIFY"; // 設定商品圖片
export const BRANCH_PRODUCT_STOCK_LIST_VIEW = "BRANCN_PRODUCT_STOCK_LIST_VIEW"; // 分店庫存列表
export const BRANCH_PRODUCT_STOCK_MODIFY = "BRANCN_PRODUCT_STOCK_MODIFY"; // 分店庫存修改

export const BRANCH_PRODUCT_LIST_VIEW = "BRANCH_PRODUCT_LIST_VIEW"; // 分店商品列表
export const BRANCH_PRODUCT_CREATE = "BRANCH_PRODUCT_CREATE"; // 分店商品新增
export const BRANCH_PRODUCT_MODIFY = "BRANCH_PRODUCT_MODIFY"; // 分店商品修改
export const BRANCH_PRODUCT_DELETE = "BRANCH_PRODUCT_DELETE"; // 分店商品刪除

export const EVENT_LIST_VIEW = "EVENT_LIST_VIEW"; // 活動清單、查看活動內容
export const EVENT_CUSTOMER_LIST_VIEW = "EVENT_CUSTOMER_LIST_VIEW"; // 活動會員清單
export const EVENT_MODIFY = "EVENT_MODIFY"; // 新增、修改、刪除活動
export const MY_NOTIFICATION = "MY_NOTIFICATION"; // 我的通知
export const MY_NOTIFICATION_LATEST = "MY_NOTIFICATION_LATEST"; // 我的最新一則通知
export const MY_CHANGE_PASSWORD = "MY_CHANGE_PASSWORD"; // 變更自己的密碼
export const MY_CHANGE_PROFILE = "MY_CHANGE_PROFILE"; // 變更自己資料
export const MY_STAFF_PROFILE = "MY_STAFF_PROFILE"; // 個人資料設定 (擴充版本)
export const MY_BIND_URI = "MY_BIND_URI"; // 顯示個人綁定連結
export const MY_EXPORT = "MY_EXPORT"; // 顯示資料匯出
export const MY_SIDEBAR_BRANCH = "MY_SIDEBAR_BRANCH" // 選單顯示分店
export const SALES_BIND_URI = "SALES_BIND_URI"; // 顯示快速綁定業務連結

export const EVENT_PRODUCT_LIST_VIEW = "EVENT_PRODUCT_LIST_VIEW"; // 商品白名單列表、查看
export const EVENT_PRODUCT_CREATE = "EVENT_PRODUCT_CREATE"; // 商品白名單新增
export const EVENT_PRODUCT_MODIFY = "EVENT_PRODUCT_MODIFY"; // 商品白名單修改

// 通路類型
export const MERCHANT_TYPES = {
  DEFAULT_TYPE: "DEFAULT",
  EC_TYPE: "EC",
  LINE_TYPE: "LINE",
  STORE: "STORE",
  EC_91_TYPE: "EC_91",
  GUARD_TYPE: "GUARD",
  POS_TYPE: "POS",
  EC_QDM: "EC_QDM",
  EC_SHOPLINE: "EC_SHOPLINE",
  EC_CYBERBIZ: "EC_CYBERBIZ",
  EC_SAW: "EC_SAW",
  EC_2DFIRE: "EC_2DFIRE",
  POS_ELC: "POS_ELC",
};

export const ROLE_LIST_VIEW = "ROLE_LIST_VIEW"; // 角色查看
export const ROLE_MODIFY = "ROLE_MODIFY"; // 角色查看

export const GROUP_MESSAGE_CREATE = "GROUP_MESSAGE_CREATE"; // 建立群組廣播

export const CHATROOM_LIST_VIEW = "CHATROOM_LIST_VIEW"; // 聊天室管理
export const CHATROOM_LIST_CREATE = "CHATROOM_LIST_CREATE"; // 聊天室管理
export const CHATROOM_LIST_MODIFY = "CHATROOM_LIST_MODIFY"; // 聊天室管理
export const CHATROOM_LIST_VIEW_MSG = "CHATROOM_LIST_VIEW_MSG"; // 聊天室管理
export const CHATROOM_LIST_CHAT_VIEW = "CHATROOM_LIST_CHAT_VIEW"; // 聊天室總覽
export const CHATROOM_LIST_CHAT_REPLY = "CHATROOM_LIST_CHAT_REPLY"; // 聊天室是否可回覆
export const CHATROOM_LIST_CHAT_ORG = "CHATROOM_LIST_CHAT_ORG"; // 顯示整個組織的聊天室
export const CHATROOM_LIST_CHAT_BRANCH_LIST = "CHATROOM_LIST_CHAT_BRANCH_LIST"; //聊天室顯示分店下拉
export const CHATROOM_LIST_CHAT_NOT_SENDER_REPLY =
  "CHATROOM_LIST_CHAT_NOT_SENDER_REPLY"; //聊天室非成員是否可亂入聊天
export const CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE =
  "CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE"; //聊天室成員內容是否可修改

export const THEME_GROUPBUYING_LIST_VIEW = "THEME_GROUPBUYING_LIST_VIEW"; // 主題團購列表
export const THEME_GROUPBUYING_CREATE = "THEME_GROUPBUYING_CREATE"; // 主題團購管理新增
export const THEME_GROUPBUYING_MODIFY = "THEME_GROUPBUYING_MODIFY"; // 主題團購管理編輯
export const THEME_GROUPBUYING_LIST_DELETED = "THEME_GROUPBUYING_LIST_DELETED"; // 主題團購管理列表

export const MODULE_LIST_VIEW = "MODULE_LIST_VIEW";
export const MODULE_CREATE = "MODULE_CREATE";
export const MODULE_MODIFY = "MODULE_MODIFY";
export const MODULE_DELETE = "MODULE_DELETE";

export const GIFT_LIST_VIEW = "GIFT_LIST_VIEW"; // 贈品列表
export const GIFT_LIST_CREATE = "GIFT_LIST_CREATE"; // 贈品新增
export const GIFT_LIST_MODIFY = "GIFT_LIST_MODIFY"; // 贈品編輯

export const POINT_LOGS_LIST_VIEW = "POINT_LOGS_LIST_VIEW"; // 點數異動紀錄
export const REDEEM_LIST_VIEW = "REDEEM_LIST_VIEW"; // 換獎紀錄

export const POST_LIST_VIEW = "POST_LIST_VIEW"; // 文章列表
export const POST_LIST_CREATE = "POST_LIST_CREATE"; // 文章建立
export const POST_LIST_MODIFY = "POST_LIST_MODIFY"; // 文章修改

export const REDEEM_CODE_LIST_VIEW = "REDEEM_CODE_LIST_VIEW"; //優惠券列表"
export const REDEEM_CODE_LIST_CREATE = "REDEEM_CODE_LIST_CREATE"; //優惠券建立"
export const REDEEM_CODE_LIST_MODIFY = "REDEEM_CODE_LIST_MODIFY"; //優惠券修改"
export const REDEEM_CODE_LIST_DELETE = "REDEEM_CODE_LIST_DELETE"; //優惠券刪除"
export const REDEEM_CODE_USED_LIST_VIEW = "REDEEM_CODE_USED_LIST_VIEW"; //優惠券使用紀錄

export const REDEEM_CODE_BUNDLE_TYPES = {
  COUPON: 0,
  GIFT_CARD: 1,
  LINE_POINT: 2,
};
export const REDEEM_CODE_BUNDLE_TYPE_TRANSLATE = {
  [REDEEM_CODE_BUNDLE_TYPES.COUPON]: "優惠券",
  [REDEEM_CODE_BUNDLE_TYPES.GIFT_CARD]: "優惠券",
  [REDEEM_CODE_BUNDLE_TYPES.LINE_POINT]: "LINE POINT",
};

export const REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES = {
  NONE: 0,
  PACKAGE: 1,
  FIXED: 2,
  RANDOM: 3,
};

export const REDEEM_CODE_BUNDLE_TAKE_TYPES = {
  DIRECT: 0,
  INPUT_CODE: 1,
  POINT: 2,
  SYSTEM_ASSIGN: 3,
  URL_TAKE: 4
};

export const REDEEM_CODE_BUNDLE_DATELINE_TYPES = {
  CUSTOM: 0,
  BY_TAKE_DATE: 1,
  SAME_AS_TAKE_TIME: 2,
};

export const REDEEM_CODE_BUNDLE_CONFIRM_TYPES = {
  AUTO: 0,
  MANUAL: 1,
  NONE: 2,
  BRANCH_CODE: 3,
};

export const REDEEM_CODE_BUNDLE_BARCODE_TYPES = {
  CODE_128: 1,
  CODE_39: 2,
  CODE_39_WITH_CHECKSUM: 3,
  PZN: 4,
  EAN_13: 5,
  EAN_8: 6,
  JAN: 7,
  ISBN_13: 8,
  ISBN_10: 9,
  ISSN: 10,
  UPC_A: 11,
};

export const REDEEM_CODE_BUNDLE_RECLAIM_TYPES = {
  RECLAIM_TYPE_DISABLED: 0,
  RECLAIM_TYPE_LIMITED: 1,
  RECLAIM_TYPE_UNLIMITED: 2,
}

export const KEYWORD_LIST_VIEW = "KEYWORD_LIST_VIEW"; // 查看關鍵字
export const KEYWORD_LIST_CREATE = "KEYWORD_LIST_CREATE"; // 新增關鍵字
export const KEYWORD_LIST_MODIFY = "KEYWORD_LIST_MODIFY"; // 刪除關鍵字

export const POYA_AUTOAWARD_VIEW = "POYA_AUTOAWARD_VIEW"; // 查看自動派獎
export const POYA_AUTOAWARD_CREATE = "POYA_AUTOAWARD_CREATE"; // 新增自動派獎
export const POYA_AUTOAWARD_MODIFY = "POYA_AUTOAWARD_MODIFY"; // 修改自動派獎

export const TAG_LIST_VIEW = "TAG_LIST_VIEW"; // 標籤管理
export const TAG_LIST_CREATE = "TAG_LIST_CREATE"; // 標籤管理
export const TAG_LIST_MODIFY = "TAG_LIST_MODIFY"; // 標籤管理

export const POINT_LIST_VIEW = "POINT_LIST_VIEW"; // 點數管理
export const POINT_LIST_CREATE = "POINT_LIST_CREATE"; // 點數管理
export const POINT_LIST_MODIFY = "POINT_LIST_MODIFY"; // 點數管理

export const MY_CUSTOMER_LIST = "MY_CUSTOMER_LIST"; // 主控台 - 我的客戶
export const MY_CUSTOMER_LIST_STAFF_TRANSFER =
  "MY_CUSTOMER_LIST_STAFF_TRANSFER"; // 我的客戶 - 業務轉移
export const MY_CUSTOMER_LIST_UNBIND = "MY_CUSTOMER_LIST_UNBIND"; // 我的客戶 - 解除綁定
export const MY_CUSTOMER_LIST_CHANGE_TYPE = "MY_CUSTOMER_LIST_CHANGE_TYPE"; // 我的客戶 - 變更類型

export const SQL_RUNNER_VIEW = "SQL_RUNNER_VIEW"; //SQL 排程檢視
export const SQL_RUNNER_MODIFY = "SQL_RUNNER_MODIFY"; // SQL 排程建立、編輯
export const SQL_RUNNER_EXECUTE = "SQL_RUNNER_EXECUTE"; // SQL 排程執行
export const MY_REDEEM_CODE_LIST_VIEW = "MY_REDEEM_CODE_LIST_VIEW"; // 我的優惠券
export const MY_DEPT_CUSTOMER_LIST = "MY_DEPT_CUSTOMER_LIST"; // 部門客戶
export const MY_OVERVIEW = "MY_OVERVIEW"; // 系統總覽

export const SHORT_URL_VIEW = "SHORT_URL_VIEW"; // 標籤管理
export const SHORT_URL_CREATE = "SHORT_URL_CREATE"; // 標籤管理
export const SHORT_URL_MODIFY = "SHORT_URL_MODIFY"; // 標籤管理

export const REWARD_CARD_LIST_VIEW = "REWARD_CARD_LIST_VIEW"; // 集點卡列表
export const REWARD_CARD_CREATE = "REWARD_CARD_CREATE"; // 集點卡建立
export const REWARD_CARD_MODIFY = "REWARD_CARD_MODIFY"; // 集點卡編輯
export const REWARD_CARD_DELETE = "REWARD_CARD_DELETE"; // 集點卡刪除
export const REWARD_MISSION_LIST_VIEW = "REWARD_MISSION_LIST_VIEW"; // 集點任務列表
export const REWARD_MISSION_CREATE = "REWARD_MISSION_CREATE"; // 集點任務建立
export const REWARD_MISSION_MODIFY = "REWARD_MISSION_MODIFY"; // 集點任務編輯
export const REWARD_MISSION_DELETE = "REWARD_MISSION_DELETE"; // 集點任務刪除
export const REWARD_GIFT_LIST_VIEW = "REWARD_GIFT_LIST_VIEW"; // 集點獎項列表
export const REWARD_GIFT_CREATE = "REWARD_GIFT_CREATE"; // 集點獎項建立
export const REWARD_GIFT_MODIFY = "REWARD_GIFT_MODIFY"; // 集點獎項編輯
export const REWARD_GIFT_DELETE = "REWARD_GIFT_DELETE"; // 集點獎項刪除

export const CUSTOMER_STAFF_TRANSFER = "CUSTOMER_STAFF_TRANSFER"; // 業務模組-業務轉移
export const CUSTOMER_STAFF_CHANGE_TYPE = "CUSTOMER_STAFF_CHANGE_TYPE"; // 業務模組-解除綁定
export const CUSTOMER_STAFF_UNBIND = "CUSTOMER_STAFF_UNBIND"; // 業務模組-變更類型
export const CUSTOMER_STAFF_FILTER_HISTORY = "CUSTOMER_STAFF_FILTER_HISTORY"; // 業務模組-過濾綁定紀錄
export const CUSTOMER_STAFF_FILTER_BOUND_TYPE =
  "CUSTOMER_STAFF_FILTER_BOUND_TYPE"; // 業務模組-過濾綁定類型
export const CUSTOMER_STAFF_FILTER_SEARCH = "CUSTOMER_STAFF_FILTER_SEARCH"; // 業務模組-搜尋欄位
export const CUSTOMER_STAFF_BIND_CUSTOMER = "CUSTOMER_STAFF_BIND_CUSTOMER"; // 業務模組-綁定客戶
export const CUSTOMER_STAFF_BIND_STAFF = "CUSTOMER_STAFF_BIND_STAFF"; // 業務模組-綁定業務

export const INVOICE_LIST_VIEW = "INVOICE_LIST_VIEW"; // 發票管理 - 發票列表

export const POINT_RULE_VIEW = "POINT_RULE_VIEW"; // 入點比例管理 - 入點比例列表
export const POINT_RULE_CREATE = "POINT_RULE_CREATE"; // 入點比例管理 - 入點比例新增
export const POINT_RULE_MODIFY = "POINT_RULE_MODIFY"; // 入點比例管理 - 入點比例修改

export const CUSTOMER_LEVEL_LIST = "CUSTOMER_LEVEL_LIST"; // 會員等級列表
export const CUSTOMER_LEVEL_CREATE = "CUSTOMER_LEVEL_CREATE"; // 會員等級新增
export const CUSTOMER_LEVEL_EDIT = "CUSTOMER_LEVEL_EDIT"; // 會員等級修改
export const CUSTOMER_LEVEL_DELETE = "CUSTOMER_LEVEL_DELETE"; // 會員等級刪除
export const CUSTOMER_LEVEL_AUTO_CHANGE_VIEW= "CUSTOMER_LEVEL_AUTO_CHANGE_VIEW" // 會員升降查看
export const CUSTOMER_LEVEL_AUTO_CHANGE_EDIT= "CUSTOMER_LEVEL_AUTO_CHANGE_EDIT" // 會員升降查看

export const INVOICE_LOG_LIST_VIEW = "INVOICE_LOG_LIST_VIEW"; // 發票審核 - 發票審核列表
export const INVOICE_LOG_MODIFY = "INVOICE_LOG_MODIFY"; // 發票審核 - 發票審核修改
export const INVOICE_LOG_DELETE = "INVOICE_LOG_DELETE"; // 發票審核 - 發票審核刪除
export const INVOICE_LOG_VERIFY = "INVOICE_LOG_VERIFY"; // 發票審核 - 發票審核

export const INVOICE_CARRIER_VIEW = "INVOICE_CARRIER_VIEW"; // 載具登錄列表

export const LOGS_LIST_VIEW = "LOGS_LIST_VIEW"; // 系統紀錄 - 系統紀錄

export const AUDIENCE_LIST_VIEW = "AUDIENCE_LIST_VIEW"; // 受眾管理 - 受眾列表
export const AUDIENCE_CREATE = "AUDIENCE_CREATE"; // 受眾管理 - 受眾新增
export const AUDIENCE_MODIFY = "AUDIENCE_MODIFY"; // 受眾管理 - 受眾修改
export const AUDIENCE_DELETE = "AUDIENCE_DELETE"; // 受眾管理 - 受眾刪出

export const BADGE_LIST_VIEW = "BADGE_LIST_VIEW"; // 徽章管理 - 徽章列表
export const BADGE_CREATE = "BADGE_CREATE"; // 徽章管理 - 徽章新增
export const BADGE_EDIT = "BADGE_EDIT"; // 徽章管理 - 徽章修改
export const BADGE_DELETE = "BADGE_DELETE"; // 徽章管理 - 徽章刪除

export const DAILY_ACT_LIST_VIEW = "DAILY_ACT_LIST_VIEW"; // 每日活動管理 - 每日活動列表
export const DAILY_ACT_LIST_CREATE = "DAILY_ACT_LIST_CREATE"; // 每日活動管理 - 每日活動新增
export const DAILY_ACT_LIST_MODIFY = "DAILY_ACT_LIST_MODIFY"; // 每日活動管理 - 每日活動修改
export const DAILY_ACT_LIST_DELETE = "DAILY_ACT_LIST_DELETE"; // 每日活動管理 - 每日活動刪除

export const MKT_CONFIG_LIST_VIEW = "MKT_CONFIG_LIST_VIEW"; // 行銷自動化管理 - 行銷自動化列表
export const MKT_CONFIG_LIST_CREATE = "MKT_CONFIG_LIST_CREATE"; // 行銷自動化管理 - 行銷自動化新增
export const MKT_CONFIG_LIST_MODIFY = "MKT_CONFIG_LIST_MODIFY"; // 行銷自動化管理 - 行銷自動化修改
export const MKT_CONFIG_LIST_DELETE = "MKT_CONFIG_LIST_DELETE"; // 行銷自動化管理 - 行銷自動化刪除

export const FILE_LIST_VIEW = "FILE_LIST_VIEW"; // 檔案管理 - 檔案管理列表
export const FILE_CREATE = "FILE_CREATE"; // 檔案管理 - 檔案管理新增
export const FILE_MODIFY = "FILE_MODIFY"; // 檔案管理 - 檔案管理修改
export const FILE_DELETE = "FILE_DELETE"; // 檔案管理 - 檔案管理刪除

export const MKT_RULE_LIST_VIEW = "MKT_RULE_LIST_VIEW"; // 行銷規則管理 - 行銷規則列表
export const MKT_RULE_LIST_CREATE = "MKT_RULE_LIST_CREATE"; // 行銷規則管理 - 行銷規則建立
export const MKT_RULE_LIST_MODIFY = "MKT_RULE_LIST_MODIFY"; // 行銷規則管理 - 行銷規則修改
export const MKT_RULE_LIST_DELETE = "MKT_RULE_LIST_DELETE"; // 行銷規則管理 - 行銷規則刪除

export const PARTNER_LIST_VIEW = "PARTNER_LIST_VIEW"; // 合作夥伴管理 - 夥伴列表
export const PARTNER_CREATE = "PARTNER_CREATE"; // 合作夥伴管理 - 夥伴新增
export const PARTNER_MODIFY = "PARTNER_MODIFY"; // 合作夥伴管理 - 夥伴修改
export const PARTNER_DELETE = "PARTNER_DELETE"; // 合作夥伴管理 - 夥伴刪除

export const PARTNER_WORKER_LIST_VIEW = "PARTNER_WORKER_LIST_VIEW"; // 合作夥伴管理 - 夥伴員工列表
export const PARTNER_WORKER_CREATE = "PARTNER_WORKER_CREATE"; // 合作夥伴管理 - 夥伴員工新增
export const PARTNER_WORKER_MODIFY = "PARTNER_WORKER_MODIFY"; // 合作夥伴管理 - 夥伴員工修改
export const PARTNER_WORKER_DELETE = "PARTNER_WORKER_DELETE"; // 合作夥伴管理 - 夥伴員工刪除
export const PARTNER_WORKER_VERIFY = "PARTNER_WORKER_VERIFY"; // 合作夥伴管理 - 夥伴員工審核

export const PARTNER_STAFF_LIST_VIEW = "PARTNER_STAFF_LIST_VIEW"; // 合作夥伴管理 - 夥伴帳號列表
export const PARTNER_STAFF_CREATE = "PARTNER_STAFF_CREATE"; // 合作夥伴管理 - 夥伴帳號新增
export const PARTNER_STAFF_MODIFY = "PARTNER_STAFF_MODIFY"; // 合作夥伴管理 - 夥伴帳號修改
export const PARTNER_STAFF_DELETE = "PARTNER_STAFF_DELETE"; // 合作夥伴管理 - 夥伴帳號刪除

export const TOKEN_LIST_VIEW = "TOKEN_LIST_VIEW"; // Token管理 - TOKEN 檢視
export const TOKEN_CREATE = "TOKEN_CREATE"; // Token管理 - TOKEN 建立
export const TOKEN_MODIFY = "TOKEN_MODIFY"; // Token管理 - TOKEN 編輯
export const TOKEN_DELETE = "TOKEN_DELETE"; // Token管理 - TOKEN 刪除

export const INVOICE_LOG_TYPES = {
  ELECTRONIC: "electronic",
  TRADITIONAL: "traditional",
  ELECTRONIC_CARRIER: "electronic_carrier"
};

export const INVOICE_LOG_STATUS = {
  INIT: "init", // 初始狀態
  E_VERIFY: "e_verify", // 電子 - 審核中
  E_VALID: "e_valid", // 電子 - 審核通過，有效的發票
  E_INVALID: "e_invalid", // 電子 - 不存在
  E_CANCEL: "e_cancel", // 電子 - 已作廢
  T_VERIFY: "t_verify", // 傳統 - 審核中
  T_VALID: "t_valid", // 傳統 - 審核通過，有效的發票
  T_INVALID: "t_invalid", // 傳統 - 不合格
  CANCEL: "cancel", // 人為取消
};

export const RICH_MENU_STATUS = {
  DRAFT: 0, // 草稿
  RESERVE: 1, // 預約
  PUBLISHED: 2, // 已發佈/已傳送
  UNPUBLISHED: 3, // 已下架/已下線
};

export const SERVER_LOG_STATUS_INIT = 0;
export const SERVER_LOG_STATUS_SUCCESS = 1;
export const SERVER_LOG_STATUS_ERROR = -1;

export const SERVER_LOG_STATUSES = {
  [SERVER_LOG_STATUS_SUCCESS]: "成功",
  [SERVER_LOG_STATUS_ERROR]: "失敗",
  [SERVER_LOG_STATUS_INIT]: "未設定",
};

export const SERVER_LOG_TYPE_DEBUG = "0";
export const SERVER_LOG_TYPE_INFO = "1";
export const SERVER_LOG_TYPE_ERROR = "2";
export const SERVER_LOG_TYPE_NOTICE = "3";
export const SERVER_LOG_TYPE_WARNING = "4";
export const SERVER_LOG_TYPE_CRITICAL = "5";
export const SERVER_LOG_TYPE_ALERT = "6";
export const SERVER_LOG_TYPE_EMERGENCY = "7";

export const SERVER_LOG_TYPES = {
  [SERVER_LOG_TYPE_DEBUG]: "DEBUG",
  [SERVER_LOG_TYPE_INFO]: "INFO",
  [SERVER_LOG_TYPE_ERROR]: "ERROR",
  [SERVER_LOG_TYPE_NOTICE]: "NOTICE",
  [SERVER_LOG_TYPE_WARNING]: "WARNING",
  [SERVER_LOG_TYPE_CRITICAL]: "CRITICAL",
  [SERVER_LOG_TYPE_ALERT]: "ALERT",
  [SERVER_LOG_TYPE_EMERGENCY]: "EMERGENCY",
};

export const PUSH_MESSAGE_STATUS = {
  DRAFT: 0, // 草稿
  RESERVE: 1, // 預約
  PUBLISHED: 2, // 已發佈/已傳送
  UNPUBLISHED: 3, // 已下架/已下線
};

export const SIDEBAR_THEMES = {
  THEME_1: "theme_1",
  THEME_2: "theme_2",
  THEME_3: "theme_3",
  THEME_4: "theme_4",
};
export const NEWS_STATUS = {
  DRAFT: 0, // 草稿
  PUBLISHED: 1, // 已發佈
  TAKE_DOWN: 2, // 已下架
};

export const SYSTEM_INFO = "SYSTEM_INFO"; //系統參數設定 - 系統資訊
export const LINE_SETTING_MODIFY = "LINE_SETTING_MODIFY"; //系統參數設定 - LINE 參數設定
export const LIFF_CARD_SETTING_MODIFY = "LIFF_CARD_SETTING_MODIFY"; //系統參數設定 - 會員卡畫面
export const LIFF_UI_SETTING_MODIFY = "LIFF_CARD_SETTING_MODIFY"; //系統參數設定 - 會員介面設定
export const SHOPLINE_SETTING_MODIFY = "SHOPLINE_SETTING_MODIFY"; //系統參數設定 - SHOPLINE 參數設定

export const MEMBER_EVENT_LIST_VIEW = "MEMBER_EVENT_LIST_VIEW"; //自動化模組 - 會員事件設定
export const ACTION_EVENT_LIST_VIEW = "ACTION_EVENT_LIST_VIEW"; //自動化模組 - 活動事件設定
export const SHOPPING_EVENT_LIST_VIEW = "SHOPPING_EVENT_LIST_VIEW"; //自動化模組 - 購物事件設定
export const CUSTOMIZE_EVENT_LIST_VIEW = "CUSTOMIZE_EVENT_LIST_VIEW"; //自動化模組 - 自定義事件

export const SUITE_SETTING = "SUITE_SETTING" // 方案套件設定

export const FORM_LIST = "FORM_LIST"; // 表單列表
export const FORM_CREATE = "FORM_CREATE"; // 表單列表
export const FORM_EDIT = "FORM_EDIT"; // 表單編輯
export const FORM_DELETE = "FORM_DELETE"; // 表單刪除
export const FORM_RESULT = "FORM_RESULT"; // 表單結果

export const PARTNER_WORKER_REPORT_LIST = "PARTNER_WORKER_REPORT_LIST" // 報表中心 - 夥伴員工報表

export const COLLECTION_LIST = "COLLECTION_LIST"; // 資料集列表查看
export const COLLECTION_CREATE = "COLLECTION_CREATE"; // 資料集新增
export const COLLECTION_EDIT = "COLLECTION_EDIT"; // 資料集編輯
export const COLLECTION_DELETE = "COLLECTION_DELETE"; // 資料集刪除
export const COLLECTION_ITEM_MANAGE = "COLLECTION_ITEM_MANAGE"; // 資料集項目管理

export const BOOKING_PRESET_LIST = "BOOKING_PRESET_LIST"; // 預約設定檔列表
export const BOOKING_PRESET_CREATE = "BOOKING_PRESET_CREATE"; // 預約設定檔新增
export const BOOKING_PRESET_EDIT = "BOOKING_PRESET_EDIT"; // 預約設定檔編輯
export const BOOKING_PRESET_DELETE = "BOOKING_PRESET_DELETE"; // 預約設定檔刪除

export const BOOKING_LIST = "BOOKING_LIST"; // 預約列表
export const BOOKING_CREATE = "BOOKING_CREATE"; // 預約新增
export const BOOKING_EDIT = "BOOKING_EDIT"; // 預約編輯
export const BOOKING_DELETE = "BOOKING_DELETE"; // 預約刪除

export const BOOKING_CALENDAR = "BOOKING_CALENDAR"; // 預約日曆
export const BOOKING_LIST_VIEW = "BOOKING_LIST_VIEW"; // 預約列表查看
