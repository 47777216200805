
function page(path) {
  return () => import(/* webpackChunkName: '' */ "@/pages/" + path);
}

import auth from "./middlewares/auth";
import guest from "./middlewares/guest";
import getOrganizationData from "./middlewares/getOrganizationData";
import initDashboardGeneralData from "./middlewares/initDashboardGeneralData";
import loadModules from "./middlewares/loadModules";
import permission from "./middlewares/permission";
import * as consts from "../consts";

export default [
  {
    path: '/:type/sso',
    name: "Sso",
    component: page("Dashboard/Sso.vue"),
  },
  {
    path: '/shopline/login',
    name: "ShoplineLogin",
    component: page("Dashboard/Shopline/Login.vue"),
  },
  {
    path: "/:org_code/auth/login",
    name: "AuthLogin",
    component: page("Auth/Login.vue"),
    meta: {
      middleware: [getOrganizationData, guest],
    },
  },
  {
    path: "/:org_code/auth/login-by-token",
    name: "AuthLoginByToken",
    component: page("Auth/LoginByToken.vue"),
  },
  {
    path: "/oauth/login",
    name: "OAuthLogin",
    component: page("Auth/OAuthLogin.vue"),
  },
  {
    path: "/:org_code/dashboard",
    component: page("Dashboard/DashboardLayout.vue"),
    meta: {
      middleware: [
        auth,
        getOrganizationData,
        initDashboardGeneralData,
        permission,
      ],
    },
    children: [
      {
        path: "jump",
        name: "DashboardJump",
        component: page("Dashboard/Jump.vue"),
      },
      {
        path: "/",
        name: "DashboardHome",
        component: page("Dashboard/Home.vue"),
      },
      {
        path: "organizations-home",
        name: "OrganizationHome",
        component: page("Dashboard/Organization/OrganizationHome.vue"),
      },
      /** 個人資料 **/
      {
        path: "me",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "profile",
            name: "Profile",
            component: page("Dashboard/Me/Profile.vue"),
          },
          {
            path: "extended-profile",
            name: "ExtendedProfile",
            component: page("Dashboard/Me/ExtendedProfile.vue"),
          },
        ],
      },
      /** 後台 我的主控台 **/
      {
        path: "my-console",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "customers",
            name: "MyConsoleStaffCustomerList",
            component: page("Dashboard/Staff/StaffCustomerList.vue"),
            meta: {
              permissions: [consts.MY_CUSTOMER_LIST],
            },
          },
          {
            path: "branch-customers",
            name: "MyConsoleBranchStaffCustomerList",
            component: page("Dashboard/Staff/StaffCustomerList.vue"),
            meta: {
              permissions: [consts.MY_DEPT_CUSTOMER_LIST],
            },
          },
          {
            path: "redeem_code_bundle",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "MyConsoleRedeemCodeBundleList",
                component: page(
                  "Dashboard/RedeemCodeBundle/RedeemCodeBundleList.vue"
                ),
                meta: {
                  permissions: [consts.MY_REDEEM_CODE_LIST_VIEW],
                },
              },
              {
                path: "create",
                name: "MyConsoleRedeemCodeBundleCreate",
                component: page(
                  "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
                ),
              },
              {
                path: ":redeemCodeBundleId",
                name: "MyConsoleRedeemCodeBundleDetail",
                component: page(
                  "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
                ),
              },
              {
                path: ":redeemCodeBundleId/edit",
                name: "MyConsoleRedeemCodeBundleEdit",
                component: page(
                  "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
                ),
              },
              {
                path: ":redeemCodeBundleId/redeem-codes",
                name: "MyConsoleRedeemCodeBundleRedeemCodes",
                component: page(
                  "Dashboard/RedeemCodeBundle/RedeemCodeList.vue"
                ),
              },
            ],
          },
          {
            path: "download-file",
            name: "MyConsoleDownloadFileList",
            component: page("Dashboard/MyConsole/DownloadFileIndex.vue"),
            meta: {
              permissions: [consts.MY_EXPORT],
            },
          },
          {
            path: "download-file/:download_file_id",
            name: "MyConsoleDownloadFileGetFile",
            component: page("Dashboard/MyConsole/MyConsoleDownloadFileGetFile.vue"),
            meta: {
              // permissions: [consts.MY_EXPORT],
            },
          },
          {
            path: "overview",
            name: "Overview",
            component: page("Dashboard/MyConsole/Overview.vue"),
            meta: {
              permissions: [consts.MY_OVERVIEW],
            },
          },
        ],
      },
      /** 後台 帳號管理 staffs */
      {
        path: "staffs",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
            middleware: [loadModules],
            modules: ['staff'],
        },
        children: [
          {
            path: "",
            name: "StaffList",
            component: page("Dashboard/Staff/StaffList.vue"),
            meta: {
              permissions: [consts.STAFF_LIST_VIEW],
              modules: ['staff'],
            },
          },
          {
            path: "create",
            name: "StaffCreate",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.STAFF_MODIFY],
              modules: ['staff'],
            },
          },
          {
            path: ":staff_id/edit",
            name: "StaffForm",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.STAFF_MODIFY],
              modules: ['staff'],
            },
          },
          {
            path: ":staff_id",
            name: "StaffView",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.STAFF_LIST_VIEW],
              modules: ['staff'],
            },
          },
          {
            path: ":staff_id/change-password",
            name: "StaffChangePassword",
            component: page("Dashboard/Staff/StaffChangePassword.vue"),
          },
          {
            path: ":staff_id/set-branch",
            name: "StaffSetBranch",
            component: page("Dashboard/Staff/StaffSetBranch.vue"),
            meta: {
              permissions: [consts.STAFF_BRANCH_MODIFY],
              middleware: [loadModules],
              modules: ['staff'],
            },
          },
          {
            path: ":staff_id/customers",
            name: "StaffCustomerList",
            component: page("Dashboard/Staff/StaffCustomerList.vue"),
            meta: {
              permissions: [consts.STAFF_CUSTOMER_VIEW],
            },
          },
          {
            path: ":staff_id/set-role",
            name: "StaffSetRole",
            component: page("Dashboard/Staff/StaffSetRole.vue"),
            meta: {
              permissions: [consts.STAFF_ROLE_MODIFY],
            },
          },
        ],
      },
      /** 後台 系統通知 notifications */
      {
        path: "blacklist",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "BlacklistList",
            component: page("Dashboard/Blacklist/BlacklistList.vue"),
            meta: {
              permissions: [consts.GROUPBUYING_BLACKLIST],
            },
          },
          {
            path: ":blacklistID",
            name: "BlacklisBlacklistHistories",
            component: page("Dashboard/Blacklist/BlacklistHistories.vue"),
            meta: {
              permissions: [consts.GROUPBUYING_BLACKLIST_REVOKE],
            },
          },
        ],
      },
      {
        path: "notifications",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "NotificationList",
            component: page("Dashboard/Notifications/NotificationList.vue"),
            meta: {
              permissions: [consts.NOTIFICATION_LIST],
            },
          },
          {
            path: "create",
            name: "NotificationCreate",
            component: page("Dashboard/Notifications/NotificationForm.vue"),
            meta: {
              permissions: [consts.NOTIFICATION_MODIFY],
            },
          },
          {
            path: "branch/:notification_id",
            name: "BranchNotificationDetail",
            component: page(
              "Dashboard/Notifications/BranchNotificationDetail.vue"
            ),
            meta: {
              permissions: [],
            },
          },
          {
            path: "branch",
            name: "BranchNotificationList",
            component: page(
              "Dashboard/Notifications/BranchNotificationList.vue"
            ),
            meta: {
              permissions: [],
            },
          },
          {
            path: ":notification_id",
            name: "NotificationView",
            component: page("Dashboard/Notifications/NotificationForm.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: ":notification_id/edit",
            name: "NotificationForm",
            component: page("Dashboard/Notifications/NotificationForm.vue"),
            meta: {
              permissions: [consts.NOTIFICATION_MODIFY],
            },
          },
        ],
      },
      /** 後台 群組管理 groups */
      {
        path: "groups",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "GroupList",
            component: page("Dashboard/Group/GroupList.vue"),
          },
          {
            path: ":group_id/customers",
            name: "GroupCustomerList",
            component: page("Dashboard/Group/CustomerList.vue"),
          },
          {
            path: ":group_id/conversation-record",
            name: "ConversationRecordList",
            component: page("Dashboard/Group/ConversationRecordList.vue"),
          },
        ],
      },
      /** 後台 群組保護 group-protect */
      {
        path: "group-protect",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "guardgroups",
            name: "GuardGroups",
            component: page("Dashboard/GroupProtect/GuardGroupList.vue"),
          },
          {
            path: "setting",
            name: "GroupProtectSetting",
            component: page("Dashboard/GroupProtect/GroupProtectSetting.vue"),
          },
          {
            path: "members",
            name: "GroupProtectMembers",
            component: page("Dashboard/GroupProtect/MemberList.vue"),
          },
          {
            path: "global-black-members",
            name: "GroupProtectBlackMembers",
            component: page("Dashboard/GroupProtect/BlackMemberList.vue"),
          },
          {
            path: "guards",
            name: "GroupProtectGuards",
            component: page("Dashboard/GroupProtect/GuardList.vue"),
          },
          {
            path: ":guardID/view",
            name: "GuardDetail",
            component: page("Dashboard/GroupProtect/GuardDetail.vue"),
          },
        ],
      },
      /** 後台 團購管理 groupbuyings */
      {
        path: "groupbuyings",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "GroupbuyingsList",
            component: page("Dashboard/Groupbuyings/GroupbuyList.vue"),
            meta: {
              permissions: [consts.GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "poya-groupbuying-order/list",
            name: "PoyaGroupbuyingOrderList",
            component: page(
              "Dashboard/Groupbuyings/PoyaGroupbuyingOrderList.vue"
            ),
            meta: {
              permissions: [consts.POYA_GROUPBUYINGORDER_SEARCH],
            },
          },
          {
            path: "create",
            name: "GroupbuyingsCreate",
            component: page(
              "Dashboard/Groupbuyings/GroupbuyingsCreateOrEdit.vue"
            ),
            meta: {
              permissions: [consts.GROUPBUYING_MODIFY],
            },
          },
          {
            path: ":groupbuyID",
            name: "GroupbuyingsDetail",
            component: page("Dashboard/Groupbuyings/GroupbuyingsDetail.vue"),
          },
          {
            path: ":groupbuyID/edit",
            name: "GroupbuyingsEdit",
            component: page(
              "Dashboard/Groupbuyings/GroupbuyingsCreateOrEdit.vue"
            ),
            meta: {
              permissions: [consts.GROUPBUYING_MODIFY],
            },
          },
        ],
      },
      /** 後台 團購管理 core_groupbuyings*/
      {
        path: "core-groupbuyings",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "draft",
            name: "CoreGroupbuyingsList",
            component: page("Dashboard/Groupbuyings/CoreGroupbuyList.vue"),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "",
            name: "CoreGroupbuyingsListV2",
            component: page("Dashboard/Groupbuyings/CoreGroupbuyListV2.vue"),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "CoreGroupbuyingsCreate",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEdit.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_CREATE],
            },
          },
          {
            path: "create-v2",
            name: "CoreGroupbuyingsCreateV2",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEditV2.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_CREATE],
            },
          },
          {
            path: ":groupbuyID/edit-v2",
            name: "CoreGroupbuyingsEditV2",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEditV2.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_MODIFY],
            },
          },
          {
            path: ":groupbuyID/view-v2",
            name: "CoreGroupbuyingsViewV2",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEditV2.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: ":groupbuyID/edit",
            name: "CoreGroupbuyingsEdit",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEdit.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_MODIFY],
            },
          },
          {
            path: ":groupbuyID/view",
            name: "CoreGroupbuyingsView",
            component: page(
              "Dashboard/Groupbuyings/CoreGroupbuyingsCreateOrEdit.vue"
            ),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: ":groupbuyID/view/v2",
            name: "CoreGroupbuyingDetailV2",
            component: page("Dashboard/Groupbuyings/CoreGroupbuyingDetail.vue"),
            meta: {
              permissions: [consts.CORE_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "products",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "CoreGroupbuyingsProductList",
                component: page("Dashboard/Groupbuyings/ProductList.vue"),
                meta: {
                  permissions: [consts.CORE_GROUPBUYING_PRODUCT_LIST],
                },
              },
              {
                path: "create",
                name: "CoreGroupbuyingsProductCreate",
                component: page("Dashboard/Groupbuyings/ProductDetail.vue"),
              },
              {
                path: ":product_id",
                name: "CoreGroupbuyingsProductDetail",
                component: page("Dashboard/Groupbuyings/ProductDetail.vue"),
              },
              {
                path: ":product_id/edit",
                name: "CoreGroupbuyingsProductEdit",
                component: page("Dashboard/Groupbuyings/ProductDetail.vue"),
              },
            ]
          }
        ],
      },
      /** 後台 總公司團購管理 groupbuyings_main */
      {
        path: "main_groupbuyings",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "MainGroupbuyingsList",
            component: page("Dashboard/MainGroupbuyings/MainGroupbuyList.vue"),
            meta: {
              permissions: [consts.MAIN_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "MainGroupbuyingsCreate",
            component: page(
              "Dashboard/MainGroupbuyings/MainGroupbuyingsCreateEditDetail.vue"
            ),
            meta: {
              permissions: [consts.MAIN_GROUPBUYING_CREATE],
            },
          },
          {
            path: "batch",
            name: "MainGroupbuyingsBatch",
            component: page(
              "Dashboard/MainGroupbuyings/BatchUpdateMainGroupbuy.vue"
            ),
            meta: {
              permissions: [consts.GROUPBUYING_SKU_QTY_UPDATE],
            },
          },
          {
            path: "product-whitelist",
            name: "ProductWhiteList",
            component: page("Dashboard/MainGroupbuyings/ProductWhiteList.vue"),
            meta: {
              permissions: [consts.EVENT_PRODUCT_LIST_VIEW],
            },
          },
          {
            path: "product-whitelist/create",
            name: "ProductWhiteListCreate",
            component: page(
              "Dashboard/MainGroupbuyings/ProductWhiteListCreate.vue"
            ),
            meta: {
              permissions: [consts.EVENT_PRODUCT_CREATE],
            },
          },
          {
            path: "product-whitelist/:event_Product_id",
            name: "ProductWhiteListDetail",
            component: page(
              "Dashboard/MainGroupbuyings/ProductWhiteListEditOrDetail.vue"
            ),
            meta: {
              permissions: [consts.EVENT_PRODUCT_LIST_VIEW],
            },
          },
          {
            path: "product-whitelist/:event_Product_id/edit",
            name: "ProductWhiteListEdit",
            component: page(
              "Dashboard/MainGroupbuyings/ProductWhiteListEditOrDetail.vue"
            ),
            meta: {
              permissions: [consts.EVENT_PRODUCT_MODIFY],
            },
          },
          {
            path: "scan",
            name: "MainGroupbuyingScanProduct",
            component: page(
              "Dashboard/MainGroupbuyings/StoreGroupbuyingScanProduct.vue"
            ),
            // meta: {
            //   permissions: [consts.STORE_GROUPBUYING_CREATE],
            // },
          },
          {
            path: "product/:id",
            name: "MainGroupbuyingUpdateImage",
            component: page(
              "Dashboard/MainGroupbuyings/StoreGroupbuyingUpdateImage.vue"
            ),
            // meta: {
            //   permissions: [consts.STORE_GROUPBUYING_CREATE],
            // },
          },
          {
            path: "draft",
            name: "MainGroupbuyingDraft",
            component: page(
              "Dashboard/MainGroupbuyings/StoreGroupbuyingDraft.vue"
            ),
            // meta: {
            //   permissions: [consts.STORE_GROUPBUYING_CREATE],
            // },
          },
          {
            path: "product/:id/create",
            name: "MainGroupbuyingCreate",
            component: page(
              "Dashboard/MainGroupbuyings/StoreGroupbuyingCreate.vue"
            ),
            // meta: {
            //   permissions: [consts.STORE_GROUPBUYING_CREATE],
            // },
          },
          {
            path: ":groupbuyID",
            name: "MainGroupbuyingsDetail",
            component: page(
              "Dashboard/MainGroupbuyings/MainGroupbuyingsCreateEditDetail.vue"
            ),
          },
          {
            path: ":groupbuyID/edit",
            name: "MainGroupbuyingsEdit",
            component: page(
              "Dashboard/MainGroupbuyings/MainGroupbuyingsCreateEditDetail.vue"
            ),
            meta: {
              permissions: [consts.MAIN_GROUPBUYING_MODIFY],
            },
          },
          {
            path: ":groupbuyID/store",
            name: "MainStoreGroupbuyingDetail",
            component: page(
              "Dashboard/MainGroupbuyings/StoreGroupbuyingEditDetail.vue"
            ),
            meta: {
              permissions: [consts.MAIN_GROUPBUYING_MODIFY],
            },
          },
          {
            path: "group-message/create",
            name: "GroupMessageCreate",
            component: page(
              "Dashboard/MainGroupbuyings/GroupMessageCreate.vue"
            ),
            meta: {
              permissions: [consts.GROUP_MESSAGE_CREATE],
            },
          },
        ],
      },
      /** 後台 門市開團管理 */
      {
        path: "store-groupbuying",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "StoreGroupbuyingList",
            component: page(
              "Dashboard/StoreGroupbuying/StoreGroupbuyingList.vue"
            ),
            meta: {
              permissions: [consts.MAIN_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "scan",
            name: "StoreGroupbuyingScanProduct",
            component: page(
              "Dashboard/StoreGroupbuying/StoreGroupbuyingScanProduct.vue"
            ),
            meta: {
              permissions: [consts.STORE_GROUPBUYING_CREATE],
            },
          },
          {
            path: ":branch_id/product/:id",
            name: "StoreGroupbuyingUpdateImage",
            component: page(
              "Dashboard/StoreGroupbuying/StoreGroupbuyingUpdateImage.vue"
            ),
            meta: {
              permissions: [consts.STORE_GROUPBUYING_CREATE],
            },
          },
          {
            path: ":branch_id/product/:id/create",
            name: "StoreGroupbuyingCreate",
            component: page(
              "Dashboard/StoreGroupbuying/StoreGroupbuyingCreate.vue"
            ),
            meta: {
              permissions: [consts.STORE_GROUPBUYING_CREATE],
            },
          },
          {
            path: "draft",
            name: "StoreGroupbuyingDraft",
            component: page(
              "Dashboard/StoreGroupbuying/StoreGroupbuyingDraft.vue"
            ),
            meta: {
              permissions: [consts.STORE_GROUPBUYING_DRAFT],
            },
          },
        ],
      },
      /** 後台 主題團購管理 theme_groupbuying */
      {
        path: "theme-groupbuying",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "ThemeGroupbuyingList",
            component: page(
              "Dashboard/ThemeGroupbuying/ThemeGroupbuyingList.vue"
            ),
            meta: {
              permissions: [consts.THEME_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "ThemeGroupbuyingCreate",
            component: page(
              "Dashboard/ThemeGroupbuying/ThemeGroupbuyingCreate.vue"
            ),
            meta: {
              permissions: [consts.THEME_GROUPBUYING_CREATE],
            },
          },
          {
            path: ":themeGroupbuyingId",
            name: "ThemeGroupbuyingDetail",
            component: page(
              "Dashboard/ThemeGroupbuying/ThemeGroupbuyingDetail.vue"
            ),
            meta: {
              permissions: [consts.THEME_GROUPBUYING_LIST_VIEW],
            },
          },
          {
            path: ":themeGroupbuyingId/edit",
            name: "ThemeGroupbuyingEdit",
            component: page(
              "Dashboard/ThemeGroupbuying/ThemeGroupbuyingEdit.vue"
            ),
            meta: {
              permissions: [consts.THEME_GROUPBUYING_MODIFY],
            },
          },
        ],
      },
      /** 後台 分店管理 */
      {
        path: "branches",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          permissions: [],
        },
        children: [
          {
            path: "",
            name: "Branches",
            component: page("Dashboard/Branches/BranchesHome.vue"),
          },
          {
            path: "create",
            name: "BranchesRootCreate",
            component: page("Dashboard/Branches/BranchesCreateOrEdit.vue"),
          },
          {
            path: "import",
            name: "BranchesRootImport",
            component: page("Dashboard/Branches/BranchesImport.vue"),
          },
          {
            path: ":branchID",
            name: "BranchesSubLayer",
            component: page("Dashboard/Branches/BranchesHome.vue"),
          },
          {
            path: ":branchID/create",
            name: "BranchesCreate",
            component: page("Dashboard/Branches/BranchesCreateOrEdit.vue"),
          },
          {
            path: ":branchID/import",
            name: "BranchesImport",
            component: page("Dashboard/Branches/BranchesImport.vue"),
          },
          {
            path: ":branchID/show",
            name: "BranchesShow",
            component: page("Dashboard/Branches/BranchesCreateOrEdit.vue"),
          },
          {
            path: ":branchID/edit",
            name: "BranchesEdit",
            component: page("Dashboard/Branches/BranchesCreateOrEdit.vue"),
          },
          {
            path: ":branchID/staffs",
            name: "BranchStaffs",
            component: page("Dashboard/Branches/StaffList.vue"),
            meta: {
              middleware: [loadModules],
              modules: ['staff'],
            }
          },
          {
            path: ":branchID/staffs/create",
            name: "BranchStaffCreate",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              middleware: [loadModules],
              modules: ['staff'],
            }
          },
          {
            path: ":branchID/staffs/:staff_id",
            name: "BranchStaffView",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              middleware: [loadModules],
              modules: ['staff'],
            }
          },
          {
            path: ":branchID/staffs/:staff_id/set-branches",
            name: "BranchStaffSetBranch",
            component: page("Dashboard/Staff/StaffSetBranch.vue"),
          },
          {
            path: ":branchID/staffs/:staff_id/customers",
            name: "BranchStaffCustomerList",
            component: page("Dashboard/Staff/StaffCustomerList.vue"),
            meta: {
              permissions: [consts.STAFF_CUSTOMER_VIEW],
            },
          },
          {
            path: ":branchID/staffs/:staff_id/set-role",
            name: "BranchStaffSetRole",
            component: page("Dashboard/Staff/StaffSetRole.vue"),
            meta: {
              permissions: [consts.STAFF_ROLE_MODIFY],
            },
          },
          {
            path: ":branchID/staffs/:staff_id/change-password",
            name: "BranchStaffChangePassword",
            component: page("Dashboard/Staff/StaffChangePassword.vue"),
          },
          {
            path: ":branchID/staffs/:staff_id/edit",
            name: "BranchStaffForm",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.STAFF_MODIFY],
              middleware: [loadModules],
              modules: ['staff'],
            },
          },
          {
            path: ":branchID/update-image",
            name: "BranchUpdateImage",
            component: page("Dashboard/Branches/BranchUpdateImage.vue"),
          },
        ],
      },
      /** 後台 Merchant 管理 */
      {
        path: "merchants",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "create",
            name: "MerchantCreate",
            component: page("Dashboard/SystemSettings/MerchantForm.vue"),
          },
          {
            path: ":merchant_id",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "/",
                name: "MerchantShow",
                component: page("Dashboard/SystemSettings/MerchantForm.vue"),
              },
              {
                path: "edit",
                name: "MerchantEdit",
                component: page("Dashboard/SystemSettings/MerchantForm.vue"),
              },
              {
                path: "templates",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                meta: {
                  permissions: [],
                },
                children: [
                  {
                    path: "",
                    name: "TemplateList",
                    component: page("Dashboard/Template/TemplateList.vue"),
                  },
                  {
                    path: ":template_id",
                    name: "TemplateShow",
                    component: page("Dashboard/Template/TemplateForm.vue"),
                  },
                  {
                    path: ":template_id",
                    name: "TemplateEdit",
                    component: page("Dashboard/Template/TemplateForm.vue"),
                  },
                ],
              },
              {
                path: "organization_variables",
                name: "OrganizationVariableListByMerchant",
                meta: {
                  permissions: [consts.ORGANIZATION_MERCHANT_VARIABLE],
                },
                component: page(
                  "Dashboard/OrganizationVariable/OrganizationVariableList.vue"
                ),
              },
              {
                path: "organization_variables/create",
                name: "OrganizationVariableCreateByMerchant",
                component: page(
                  "Dashboard/OrganizationVariable/OrganizationVariableCreate.vue"
                ),
              },
              {
                path: "organization_variables/:organization_variable_id",
                name: "OrganizationVariableShowByMerchant",
                component: page(
                  "Dashboard/OrganizationVariable/OrganizationVariableShow.vue"
                ),
              },
              {
                path: "organization_variables/:organization_variable_id/edit",
                name: "OrganizationVariableEditByMerchant",
                component: page(
                  "Dashboard/OrganizationVariable/OrganizationVariableEdit.vue"
                ),
              },
              {
                path: "organization_variables/:organization_variable_id/config",
                name: "OrganizationVariableConfigByMerchant",
                component: page(
                  "Dashboard/OrganizationVariable/OrganizationVariableConfigForm.vue"
                ),
              },
            ],
          },
        ],
      },
      {
        path: "customer",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          roles: [consts.ROLE_ADMIN, consts.ROLE_SYS_ADMIN, consts.ROLE_STAFF],
        },
        children: [
          {
            path: "/",
            name: "CustomerList",
            component: page("Dashboard/Customer/CustomerListWithProfile.vue"),
            meta: {
              middleware:[loadModules],
              modules:['customer']
            }
          },
          {
            path: "import",
            name: "CustomerListImport",
            component: page("Dashboard/Customer/CustomerListImport.vue"),
            meta: {
              permissions: [consts.CUSTOMER_LIST_IMPORT],
              middleware:[loadModules],
              modules:['customer']
            },
          },
          {
            path: "oachatroom",
            name: "CustomerOAChatroom",
            component: page("Dashboard/Customer/OAChatroom.vue"),
          },
          {
            path: ":customerID",
            name: "CustomerDetailView",
            meta: {
              permissions: [consts.CUSTOMER_VIEW],
            },
            component: page("Dashboard/Customer/CustomerDetail.vue"),
          },
          {
            path: ":customerID/edit",
            name: "CustomerDetailEdit",
            meta: {
              middleware:[loadModules],
              modules:['customer']
            },
            component: page("Dashboard/Customer/CustomerForm.vue"),
          },
          {
            path: "levels/:id/customers",
            name: "CustomerLevelsCustomerList",
            component: page("Dashboard/Customer/CustomerLevelCustomerList.vue"),
            meta: {
              middleware: [loadModules],
              modules: ['customer'],
            }
          },
          {
            path: "levels/:id/customers/logs",
            name: "CustomerLevelsLog",
            component: page("Dashboard/Customer/CustomerLevelLog.vue"),
          },
          {
            path: "levels/:id/auto-change",
            name: "CustomerLevelsAutoChangeForm",
            component: page("Dashboard/Customer/CustomerLevelAutoChangeForm.vue"),
          },
        ],
      },
      {
        path: "customer-levels",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          roles: [consts.ROLE_ADMIN, consts.ROLE_SYS_ADMIN, consts.ROLE_STAFF],
        },
        children: [
          {
            path: "/",
            name: "CustomerLevelsList",
            component: page("Dashboard/Customer/CustomerLevelsList.vue"),
          },
          {
            path: "create",
            name: "CustomerLevelsCreate",
            component: page("Dashboard/Customer/CustomerLevelsForm.vue"),
          },
          {
            path: ":id",
            name: "CustomerLevelsView",
            component: page("Dashboard/Customer/CustomerLevelsForm.vue"),
          },
          {
            path: "edit/:id",
            name: "CustomerLevelsEdit",
            component: page("Dashboard/Customer/CustomerLevelsForm.vue"),
          },
        ],
      },
      /** 後台 會員受眾管理 管理 */
      {
        path: "audience",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "/",
            name: "AudienceList",
            component: page("Dashboard/Audience/AudienceList.vue"),
          },
          {
            path: "create",
            name: "AudienceCreate",
            component: page("Dashboard/Audience/AudienceForm.vue"),
          },
          {
            path: ":audienceId",
            name: "AudienceView",
            component: page("Dashboard/Audience/AudienceForm.vue"),
          },
          {
            path: ":audienceId/edit",
            name: "AudienceEdit",
            component: page("Dashboard/Audience/AudienceForm.vue"),
          },
          {
            path: ":audienceId/customers",
            name: "AudienceCustomersList",
            component: page("Dashboard/Audience/AudienceCustomersList.vue"),
          }
        ],
      },

      {
        path: "organization_variables",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "OrganizationVariableList",
            meta: {
              permissions: [consts.ORGANIZATION_VARIABLE],
            },
            component: page(
              "Dashboard/OrganizationVariable/OrganizationVariableList.vue"
            ),
          },
          {
            path: "create",
            name: "OrganizationVariableCreate",
            component: page(
              "Dashboard/OrganizationVariable/OrganizationVariableCreate.vue"
            ),
          },
          {
            path: ":organization_variable_id",
            name: "OrganizationVariableShow",
            component: page(
              "Dashboard/OrganizationVariable/OrganizationVariableShow.vue"
            ),
          },
          {
            path: ":organization_variable_id/edit",
            name: "OrganizationVariableEdit",
            component: page(
              "Dashboard/OrganizationVariable/OrganizationVariableEdit.vue"
            ),
          },
          {
            path: ":organization_variable_id/config",
            name: "OrganizationVariableConfig",
            component: page(
              "Dashboard/OrganizationVariable/OrganizationVariableConfigForm.vue"
            ),
          },
        ],
      },
      /** 後台 訂單管理 */
      {
        path: "orders",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
            middleware: [loadModules],
            modules: ['order'],
        },
        children: [
          {
            path: "",
            name: "OrderList",
            component: page("Dashboard/Order/OrderList.vue"),
            meta: {
              permissions: [consts.ORDER_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "OrderCreate",
            component: page("Dashboard/Order/OrderDetail.vue"),
          },
          {
            path: ":order_id",
            name: "OrderDetail",
            component: page("Dashboard/Order/OrderDetail.vue"),
          },
          {
            path: ":order_id/edit",
            name: "OrderEdit",
            component: page("Dashboard/Order/OrderDetail.vue"),
          },
        ],
      },
      {
        path: "chatrooms",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "ChatroomIndex",
            component: page("Dashboard/Chatrooms/ChatroomIndex.vue"),
            meta: {
              permissions: [consts.CHATROOM_LIST_CHAT_VIEW],
            },
          },
          {
            path: "list",
            name: "ChatroomList",
            component: page("Dashboard/Chatrooms/ChatroomList.vue"),
            meta: {
              permissions: [consts.CHATROOM_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "ChatroomCreate",
            component: page("Dashboard/Chatrooms/ChatroomForm.vue"),
          },
          {
            path: ":chatroomId/edit",
            name: "ChatroomEdit",
            component: page("Dashboard/Chatrooms/ChatroomForm.vue"),
          },
          {
            path: ":chatroomId/messages",
            name: "ChatroomMessageList",
            component: page("Dashboard/Chatrooms/ChatroomMessageList.vue"),
            meta: {
              permissions: [consts.CHATROOM_LIST_VIEW_MSG],
            },
          },
          {
            path: ":chatroomId/senders",
            name: "ChatroomSenderList",
            component: page("Dashboard/Chatrooms/ChatroomSenderList.vue"),
            meta: {
              permissions: [consts.CHATROOM_LIST_VIEW],
            },
          },
        ],
      },
      {
        path: "oa",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        // meta: {
        //   TODO add permission
        //   permissions: [consts.]
        // },
        children: [
          {
            path: "ui",
            name: "OAMessage",
            component: page("Dashboard/OA/OAMessageUI.vue"),
            meta: {
              //permissions: [consts.OA_MESSAGE_VIEW],
            },
          },
          {
            path: "push-message",
            name: "PushMessage",
            component: page("Dashboard/OA/PushMessage.vue"),
            meta: {},
          },
          {
            path: "push-message/:messageID",
            name: "PushMessageDetail",
            component: page("Dashboard/OA/PushMessageTemplateDetail.vue"),
          },
          {
            path: "push-message/:messageID/edit",
            name: "PushMessageDetailEdit",
            component: page("Dashboard/OA/PushMessageTemplateDetail.vue"),
          },
          {
            path: "push-message-template",
            name: "PushMessageTemplate",
            component: page("Dashboard/OA/PushMessageTemplate.vue"),
            meta: {},
          },
          {
            path: "push-message-template/:messageID",
            name: "PushMessageTemplateDetail",
            component: page("Dashboard/OA/PushMessageTemplateDetail.vue"),
          },
          {
            path: "push-message-template/:messageID/edit",
            name: "PushMessageTemplateDetailEdit",
            component: page("Dashboard/OA/PushMessageTemplateDetail.vue"),
          },
          {
            path: "richmenu",
            name: "RichMenu",
            component: page("Dashboard/OA/RichMenu.vue"),
            meta: {
              permissions: [consts.LINE_MENU_LIST_VIEW],
            },
          },
          {
            path: "richmenu/create",
            name: "RichMenuCreate",
            component: page("Dashboard/OA/RichMenuDetail.vue"),
            meta: {
              permissions: [consts.LINE_MENU_CREATE],
            },
          },
          {
            path: "richmenu/:menuID",
            name: "RichMenuShow",
            component: page("Dashboard/OA/RichMenuDetail.vue"),
            meta: {
              permissions: [consts.LINE_MENU_LIST_VIEW],
            },
          },
          {
            path: "richmenu/:menuID/edit",
            name: "RichMenuEdit",
            component: page("Dashboard/OA/RichMenuDetail.vue"),
            meta: {
              permissions: [consts.LINE_MENU_MODIFY],
            },
          },
          {
            path: "richmenu/:menuID/customers",
            name: "RichMenuCustomerList",
            component: page("Dashboard/OA/RichMenuCustomerList.vue"),
            meta: {
              permissions: [consts.LINE_MENU_LIST_VIEW],
            },
          },
        ],
      },
      /** 後台 商品管理 */
      {
        path: "products",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        // meta: {
        //   TODO add permission
        //   permissions: [consts.]
        // },
        children: [
          {
            path: "",
            name: "ProductList",
            component: page("Dashboard/Product/ProductList.vue"),
            meta: {
              permissions: [consts.PRODUCT_LIST_VIEW],
            },
          },
          {
            path: "branch-product-stock-list",
            name: "BranchProductStockList",
            component: page("Dashboard/Product/BranchProductStockList.vue"),
            meta: {
              permissions: [consts.BRANCH_PRODUCT_STOCK_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "ProductCreate",
            component: page("Dashboard/Product/ProductDetail.vue"),
          },
          {
            path: ":product_id",
            name: "ProductDetail",
            component: page("Dashboard/Product/ProductDetail.vue"),
          },
          {
            path: ":product_id/edit",
            name: "ProductEdit",
            component: page("Dashboard/Product/ProductDetail.vue"),
          },
          {
            path: ":product_id/update-image",
            name: "ProductUpdateImage",
            component: page("Dashboard/Product/ProductUpdateImage.vue"),
          },
        ],
      },

      /** 後台 分店商品管理 */
      {
        path: "branch-products",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "BranchProductList",
            component: page("Dashboard/Product/ProductList.vue"),
            meta: {
              permissions: [consts.BRANCH_PRODUCT_LIST_VIEW],
            },
          },
        ],
      },

      /** 後台 活動管理 events */
      {
        path: "events",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "EventList",
            component: page("Dashboard/Event/EventList.vue"),
            meta: {
              permissions: [consts.EVENT_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "EventCreate",
            component: page("Dashboard/Event/EventForm.vue"),
            meta: {
              permissions: [consts.EVENT_MODIFY],
            },
          },
          {
            path: ":event_id/edit",
            name: "EventForm",
            component: page("Dashboard/Event/EventForm.vue"),
            meta: {
              permissions: [consts.EVENT_MODIFY],
            },
          },
          {
            path: ":event_id/redeem-codes",
            name: "EventRedeemCodeList",
            component: page("Dashboard/Event/RedeemCodeList.vue"),
            meta: {
              permissions: [consts.EVENT_LIST_VIEW],
            },
          },
          {
            path: ":event_id/customers",
            name: "EventCustomerList",
            component: page("Dashboard/Event/CustomerList.vue"),
            meta: {
              permissions: [consts.EVENT_CUSTOMER_LIST_VIEW],
            },
          },
          {
            path: ":event_id",
            name: "EventView",
            component: page("Dashboard/Event/EventForm.vue"),
            meta: {
              permissions: [consts.EVENT_LIST_VIEW],
            },
          },
        ],
      },

      /** 後台 關鍵字管理 events */
      {
        path: "keywords",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "KeywordList",
            component: page("Dashboard/Keyword/KeywordList.vue"),
            meta: {
              permissions: [consts.KEYWORD_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "KeywordCreate",
            component: page("Dashboard/Keyword/KeywordForm.vue"),
            meta: {
              permissions: [consts.KEYWORD_LIST_CREATE],
            },
          },
          {
            path: ":keyword_id",
            name: "KeywordView",
            component: page("Dashboard/Keyword/KeywordForm.vue"),
            meta: {
              permissions: [consts.KEYWORD_LIST_VIEW],
            },
          },
          {
            path: ":keyword_id/edit",
            name: "KeywordEdit",
            component: page("Dashboard/Keyword/KeywordForm.vue"),
            meta: {
              permissions: [consts.KEYWORD_LIST_MODIFY],
            },
          },
        ],
      },
      /** 後台每日活動 管理 */
      {
        path: "daily-activities",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "DailyActivityList",
            component: page("Dashboard/DailyActivity/DailyActivityList.vue"),
            meta: {
              permissions: [consts.DAILY_ACT_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "DailyActivityCreate",
            component: page("Dashboard/DailyActivity/DailyActivityForm.vue"),
            meta: {
              permissions: [consts.DAILY_ACT_LIST_CREATE],
            },
          },
          {
            path: ":activity_id",
            name: "DailyActivityView",
            component: page("Dashboard/DailyActivity/DailyActivityForm.vue"),
            meta: {
              permissions: [consts.DAILY_ACT_LIST_VIEW],
            },
          },
          {
            path: ":activity_id/edit",
            name: "DailyActivityEdit",
            component: page("Dashboard/DailyActivity/DailyActivityForm.vue"),
            meta: {
              permissions: [consts.DAILY_ACT_LIST_MODIFY],
            },
          },
        ],
      },

      /** 後台 上傳檔案管理*/
      {
        path: "upload-files",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          middleware: [loadModules],
          modules: ['upload_files'],
        },
        children: [
          {
            path: "",
            name: "UploadFileList",
            component: page("Dashboard/UploadFile/UploadFileList.vue"),
            meta: {
              permissions: [consts.FILE_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "UploadFileCreate",
            component: page("Dashboard/UploadFile/UploadFileForm.vue"),
            meta: {
              permissions: [consts.FILE_CREATE],
            },
          },
          {
            path: ":upload_file_id",
            name: "UploadFileView",
            component: page("Dashboard/UploadFile/UploadFileForm.vue"),
            meta: {
              permissions: [consts.FILE_LIST_VIEW],
            },
          },
          {
            path: ":upload_file_id/edit",
            name: "UploadFileEdit",
            component: page("Dashboard/UploadFile/UploadFileForm.vue"),
            meta: {
              permissions: [consts.FILE_MODIFY],
            },
          },
        ],
      },
      /** 後台 角色管理 roles */
      {
        path: "roles",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "RoleList",
            component: page("Dashboard/Role/RoleList.vue"),
            meta: {
              permissions: [consts.ROLE_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "RoleCreate",
            component: page("Dashboard/Role/RoleCreate.vue"),
            meta: {
              permissions: [consts.ROLE_MODIFY],
            },
          },
          {
            path: ":role_id",
            name: "RoleShow",
            component: page("Dashboard/Role/RoleShow.vue"),
            meta: {
              permissions: [consts.ROLE_LIST_VIEW],
            },
          },
          {
            path: ":role_id/edit",
            name: "RoleEdit",
            component: page("Dashboard/Role/RoleEdit.vue"),
            meta: {
              permissions: [consts.ROLE_MODIFY],
            },
          },
          {
            path: ":role_id/staffs",
            name: "RoleStaffList",
            component: page("Dashboard/Role/RoleStaffList.vue"),
            meta: {
              permissions: [consts.ROLE_LIST_VIEW],
            },
          },
          {
            path: ":role_id/permissions/edit",
            name: "RolePermissionEdit",
            component: page("Dashboard/Role/RolePermissionEdit.vue"),
            meta: {
              permissions: [consts.ROLE_LIST_VIEW],
            },
          },
        ],
      },

      /** 後台 全系統管理 */
      {
        path: "system-settings",
        name: "SystemSettingsHome",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: {name: "OrganizationList"},
        children: [
          {
            path: "organizations",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "OrganizationList",
                component: page(
                  "Dashboard/SystemSettings/OrganizationList.vue"
                ),
                meta: {
                  permissions: [consts.ORGANIZATION_LIST_VIEW],
                },
              },
              {
                path: "create",
                name: "OrganizationCreate",
                component: page(
                  "Dashboard/SystemSettings/OrganizationForm.vue"
                ),
              },
              {
                path: ":orgId",
                name: "OrganizationShow",
                component: page(
                  "Dashboard/SystemSettings/OrganizationForm.vue"
                ),
              },
              {
                path: ":orgId/edit",
                name: "OrganizationEdit",
                component: page(
                  "Dashboard/SystemSettings/OrganizationForm.vue"
                ),
              },
              {
                path: ":orgId/merchants",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "",
                    name: "OrgMerchantList",
                    component: page(
                      "Dashboard/SystemSettings/MerchantList.vue"
                    ),
                  },
                  {
                    path: "create",
                    name: "OrgMerchantCreate",
                    component: page(
                      "Dashboard/SystemSettings/MerchantForm.vue"
                    ),
                  },
                  {
                    path: ":merchantId/edit",
                    name: "OrgMerchantEdit",
                    component: page(
                      "Dashboard/SystemSettings/MerchantForm.vue"
                    ),
                  },
                ],
              },
              /** 後台 角色管理 roles */
              {
                path: ":orgId/roles",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "",
                    name: "OrgRoleList",
                    component: page("Dashboard/Role/RoleList.vue"),
                    meta: {
                      permissions: [consts.ROLE_LIST_VIEW],
                    },
                  },
                  {
                    path: "create",
                    name: "OrgRoleCreate",
                    component: page("Dashboard/Role/RoleCreate.vue"),
                    meta: {
                      permissions: [consts.ROLE_MODIFY],
                    },
                  },
                  {
                    path: ":role_id",
                    name: "OrgRoleShow",
                    component: page("Dashboard/Role/RoleShow.vue"),
                    meta: {
                      permissions: [consts.ROLE_LIST_VIEW],
                    },
                  },
                  {
                    path: ":role_id/edit",
                    name: "OrgRoleEdit",
                    component: page("Dashboard/Role/RoleEdit.vue"),
                    meta: {
                      permissions: [consts.ROLE_MODIFY],
                    },
                  },
                  {
                    path: ":role_id/staffs",
                    name: "OrgRoleStaffList",
                    component: page("Dashboard/Role/RoleStaffList.vue"),
                    meta: {
                      permissions: [consts.ROLE_LIST_VIEW],
                    },
                  },
                  {
                    path: ":role_id/staffs/:staff_id/change-password",
                    name: "OrganizationsStaffChangePassword",
                    component: page("Dashboard/Staff/StaffChangePassword.vue"),
                  },
                  {
                    path: ":role_id/permissions/edit",
                    name: "OrgRolePermissionEdit",
                    component: page("Dashboard/Role/RolePermissionEdit.vue"),
                    meta: {
                      permissions: [consts.ROLE_LIST_VIEW],
                    },
                  },
                ],
              },
              {
                path: ":orgId/modules",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "",
                    name: "ModuleList",
                    component: page("Dashboard/SystemSettings/ModuleList.vue"),
                  },
                  {
                    path: "create",
                    name: "ModuleCreate",
                    component: page("Dashboard/SystemSettings/ModuleForm.vue"),
                  },
                  {
                    path: ":moduleId",
                    name: "ModuleShow",
                    component: page("Dashboard/SystemSettings/ModuleForm.vue"),
                  },
                  {
                    path: ":moduleId/edit",
                    name: "ModuleEdit",
                    component: page("Dashboard/SystemSettings/ModuleForm.vue"),
                  },
                  {
                    path: ":moduleId/config",
                    name: "ModuleConfig",
                    component: page(
                      "Dashboard/SystemSettings/ModuleConfigForm.vue"
                    ),
                    meta: {
                      middleware: [loadModules],
                      modules: ['file'],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      /** 後台 點數管理 */
      {
        path: "point-logs",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PointLogList",
            component: page("Dashboard/PointLog/PointLogList.vue"),
            meta: {
              permissions: [consts.POINT_LOGS_LIST_VIEW],
            },
          },
          {
            path: ":pointLogId/point",
            name: "PointDetail",
            component: page("Dashboard/PointLog/PointDetail.vue"),
            meta: {
              permissions: [consts.POINT_LOGS_LIST_VIEW],
            },
          },
          {
            path: "redeem",
            name: "RedeemLogList",
            component: page("Dashboard/PointLog/RedeemLogList.vue"),
            meta: {
              permissions: [consts.REDEEM_LIST_VIEW],
            },
          },
        ],
      },
      {
        path: "points",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PointList",
            component: page("Dashboard/Point/PointList.vue"),
            meta: {
              permissions: [consts.POINT_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "PointCreate",
            component: page("Dashboard/Point/PointForm.vue"),
            meta: {
              permissions: [consts.POINT_LIST_CREATE],
            },
          },
        ],
      },
      {
        path: "merchant/:merchant_id",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "points/:point_id",
            name: "PointView",
            component: page("Dashboard/Point/PointForm.vue"),
            meta: {
              permissions: [consts.POINT_LIST_CREATE],
            },
          },
          {
            path: "points/:point_id/edit",
            name: "PointEdit",
            component: page("Dashboard/Point/PointForm.vue"),
            meta: {
              permissions: [consts.POINT_LIST_MODIFY],
            },
          },
        ],
      },
      /** 後台 獎品管理 */
      {
        path: "gift",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "GiftList",
            component: page("Dashboard/Gift/GiftList.vue"),
            meta: {
              permissions: [consts.GIFT_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "GiftCreate",
            component: page("Dashboard/Gift/GiftDetail.vue"),
          },
          {
            path: ":gift_id",
            name: "GiftDetail",
            component: page("Dashboard/Gift/GiftDetail.vue"),
          },
          {
            path: ":gift_id/edit",
            name: "GiftEdit",
            component: page("Dashboard/Gift/GiftDetail.vue"),
          },
          {
            path: ":gift_id/update-image",
            name: "GiftUpdateImage",
            component: page("Dashboard/Gift/GiftUpdateImage.vue"),
          },
        ],
      },
      /** 後台 優惠券管理 */
      {
        path: "redeem_code_bundle",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "belonged-redeem-codes",
            name: "BelongedRedeemCodeList",
            component: page(
              "Dashboard/RedeemCodeBundle/BelongedRedeemCodeList.vue"
            ),
            meta: {
              permissions: [consts.REDEEM_CODE_USED_LIST_VIEW],
            },
          },
          {
            path: "",
            name: "RedeemCodeBundleList",
            component: page(
              "Dashboard/RedeemCodeBundle/RedeemCodeBundleList.vue"
            ),
            meta: {
              permissions: [consts.REDEEM_CODE_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "RedeemCodeBundleCreate",
            component: page(
              "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
            ),
          },
          {
            path: ":redeemCodeBundleId",
            name: "RedeemCodeBundleDetail",
            component: page(
              "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
            ),
          },
          {
            path: ":redeemCodeBundleId/edit",
            name: "RedeemCodeBundleEdit",
            component: page(
              "Dashboard/RedeemCodeBundle/RedeemCodeBundleForm.vue"
            ),
          },
          {
            path: ":redeemCodeBundleId/redeem-codes",
            name: "RedeemCodeBundleRedeemCodes",
            component: page("Dashboard/RedeemCodeBundle/RedeemCodeList.vue"),
          },
        ],
      },

      /** 後台 合作夥伴管理*/
      {
        path: "partner",
        meta: {
          middleware: [loadModules],
          modules: ['partner'],
        },
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PartnerList",
            component: page("Dashboard/Partner/PartnerList.vue"),
            meta: {
              permissions: [consts.PARTNER_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "PartnerCreate",
            component: page("Dashboard/Partner/PartnerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_CREATE],
            },
          },
          {
            path: ":partnerId",
            name: "PartnerView",
            component: page("Dashboard/Partner/PartnerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/edit",
            name: "PartnerEdit",
            component: page("Dashboard/Partner/PartnerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_MODIFY],
            },
          },
          {
            path: ":partnerId/partner-workers",
            name: "PartnerWorkerListByPartner",
            component: page("Dashboard/PartnerWorker/PartnerWorkerList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/partner-workers/:partnerWorkerId",
            name: "PartnerWorkerFormByPartner",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/partner-workers/create",
            name: "PartnerWorkerCreateByPartner",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_CREATE],
            },
          },
          {
            path: ":partnerId/partner-workers/:partnerWorkerId/edit",
            name: "PartnerWorkerFormEditByPartner",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/staffs",
            name: "PartnerStaffList",
            component: page("Dashboard/PartnerStaff/PartnerStaffList.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/staffs/create",
            name: "PartnerStaffCreate",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_CREATE],
            },
          },
          {
            path: ":partnerId/staffs/:staff_id",
            name: "PartnerStaffView",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_LIST_VIEW],
            },
          },
          {
            path: ":partnerId/staffs/:staff_id",
            name: "PartnerStaffEdit",
            component: page("Dashboard/Staff/StaffForm.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_MODIFY],
            },
          },
          {
            path: ":partnerId/staffs/:staff_id/set-partners",
            name: "PartnerStaffSetPartner",
            component: page("Dashboard/Staff/StaffSetPartner.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_LIST_VIEW],
            },
          },
        ],
      },

      /** 後台 夥伴員工管理*/
      {
        path: "partner-worker",
        meta: {
          middleware: [loadModules],
          modules: ['partner'],
        },
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "list",
            name: "PartnerWorkerList",
            component: page("Dashboard/PartnerWorker/PartnerWorkerList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: "list/:partnerWorkerId",
            name: "PartnerWorkerView",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: "list/:partnerWorkerId/edit",
            name: "PartnerWorkerEdit",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_MODIFY],
            },
          },
          {
            path: "verifying",
            name: "PartnerWorkerListVerifying",
            component: page("Dashboard/PartnerWorker/PartnerWorkerList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: "verifying/:partnerWorkerId",
            name: "PartnerWorkerVerifyingView",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_LIST_VIEW],
            },
          },
          {
            path: "verifying/:partnerWorkerId/edit",
            name: "PartnerWorkerVerifyingEdit",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_MODIFY],
            },
          },
          {
            path: "create",
            name: "PartnerWorkerCreate",
            component: page("Dashboard/PartnerWorker/PartnerWorkerForm.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_CREATE],
            },
          },

        ],
      },
      // 夥伴帳號管理
      {
        path: "partner-staff",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PartnerStaffEntry",
            component: page("Dashboard/PartnerStaff/PartnerStaffList.vue"),
            meta: {
              permissions: [consts.PARTNER_STAFF_LIST_VIEW],
            },
          },
        ],
      },

      {
        path: "tokens",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "TokenList",
            component: page("Dashboard/Token/TokenList.vue"),
            meta: {
              permissions: [consts.TOKEN_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "TokenCreate",
            component: page("Dashboard/Token/TokenForm.vue"),
            meta: {
              permissions: [consts.TOKEN_CREATE],
            },
          },
          {
            path: ":tokenId",
            name: "TokenView",
            component: page("Dashboard/Token/TokenForm.vue"),
            meta: {
              permissions: [consts.TOKEN_LIST_VIEW],
            },
          },
          {
            path: ":tokenId/edit",
            name: "TokenEdit",
            component: page("Dashboard/Token/TokenForm.vue"),
            meta: {
              permissions: [consts.TOKEN_MODIFY],
            },
          },
        ],
      },

      /** 後台 文章管理 */
      {
        path: "posts",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PostList",
            component: page("Dashboard/Post/PostList.vue"),
            meta: {
              permissions: [consts.POST_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "PostCreate",
            component: page("Dashboard/Post/PostForm.vue"),
            meta: {
              permissions: [consts.POST_LIST_CREATE],
            },
          },
          {
            path: ":postId/edit",
            name: "PostEdit",
            component: page("Dashboard/Post/PostForm.vue"),
            meta: {
              permissions: [consts.POST_LIST_MODIFY],
            },
          },
          {
            path: ":postId",
            name: "PostView",
            component: page("Dashboard/Post/PostForm.vue"),
            meta: {
              permissions: [consts.POST_LIST_VIEW],
            },
          },
        ],
      },
      /** 後台 標籤管理 */
      {
        path: "tags",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "TagList",
            component: page("Dashboard/Tag/TagList.vue"),
            meta: {
              permissions: [consts.TAG_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "TagCreate",
            component: page("Dashboard/Tag/TagForm.vue"),
            meta: {
              permissions: [consts.TAG_LIST_CREATE],
            },
          },
          {
            path: ":tag_id",
            name: "TagView",
            component: page("Dashboard/Tag/TagForm.vue"),
            meta: {
              permissions: [consts.TAG_LIST_CREATE],
            },
          },
          {
            path: ":tag_id/edit",
            name: "TagEdit",
            component: page("Dashboard/Tag/TagForm.vue"),
            meta: {
              permissions: [consts.TAG_LIST_MODIFY],
            },
          },
        ],
      },
      /* 自動派獎設定 */
      {
        path: "autoawards",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "AutoawardList",
            component: page("Dashboard/Autoaward/AutoawardList.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_VIEW],
            },
          },
          {
            path: "createv2",
            name: "AutoawardCreate",
            component: page("Dashboard/Autoaward/AutoawardForm.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_CREATE],
            },
          },
          {
            path: ":autoawardId/editv2",
            name: "AutoawardEdit",
            component: page("Dashboard/Autoaward/AutoawardForm.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_MODIFY],
            },
          },
          {
            path: "v2/:autoawardId",
            name: "AutoawardView",
            component: page("Dashboard/Autoaward/AutoawardForm.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_MODIFY],
            },
          },
          {
            path: "create",
            name: "AutoawardCreateV3",
            component: page("Dashboard/Autoaward/AutoawardFormV3.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_CREATE],
            },
          },
          {
            path: ":autoawardId/edit",
            name: "AutoawardEditV3",
            component: page("Dashboard/Autoaward/AutoawardFormV3.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_MODIFY],
            },
          },
          {
            path: ":autoawardId",
            name: "AutoawardViewV3",
            component: page("Dashboard/Autoaward/AutoawardFormV3.vue"),
            meta: {
              permissions: [consts.POYA_AUTOAWARD_MODIFY],
            },
          },
        ],
      },
      {
        path: "no-permission",
        name: "NoPermission403",
        component: page("Error/NoPermission403.vue"),
      },
      /** 後台 run sql  */
      {
        path: "sql-runners",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "SqlRunnerList",
            component: page("Dashboard/SqlRunner/SqlRunnerList.vue"),
            meta: {
              permissions: [consts.SQL_RUNNER_VIEW],
            },
          },
          {
            path: "create",
            name: "SqlRunnerCreate",
            component: page("Dashboard/SqlRunner/SqlRunnerCreate.vue"),
            meta: {
              permissions: [consts.SQL_RUNNER_MODIFY],
            },
          },
          {
            path: ":sql_runner_id",
            name: "SqlRunnerView",
            component: page("Dashboard/SqlRunner/SqlRunnerShow.vue"),
            meta: {
              permissions: [consts.SQL_RUNNER_VIEW],
            },
          },
          {
            path: ":sql_runner_id/edit",
            name: "SqlRunnerEdit",
            component: page("Dashboard/SqlRunner/SqlRunnerEdit.vue"),
            meta: {
              permissions: [consts.SQL_RUNNER_MODIFY],
            },
          },
        ],
      },

      /** 後台 短網址管理 */
      {
        path: "short-urls",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "ShortUrlList",
            component: page("Dashboard/ShortUrl/ShortUrlList.vue"),
            meta: {
              permissions: [consts.SHORT_URL_VIEW],
            },
          },
          {
            path: "create",
            name: "ShortUrlCreate",
            component: page("Dashboard/ShortUrl/ShortUrlForm.vue"),
            meta: {
              permissions: [consts.SHORT_URL_CREATE],
            },
          },
          {
            path: ":short_url_id",
            name: "ShortUrlView",
            component: page("Dashboard/ShortUrl/ShortUrlForm.vue"),
            meta: {
              permissions: [consts.SHORT_URL_VIEW],
            },
          },
          {
            path: ":short_url_id/edit",
            name: "ShortUrlEdit",
            component: page("Dashboard/ShortUrl/ShortUrlForm.vue"),
            meta: {
              permissions: [consts.SHORT_URL_MODIFY],
            },
          },
        ],
      },

      /** 後台 集點卡管理 */
      {
        path: "reward-cards",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "RewardCardList",
            component: page("Dashboard/RewardCard/RewardCardList.vue"),
            meta: {
              permissions: [consts.REWARD_CARD_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "RewardCardCreate",
            component: page("Dashboard/RewardCard/RewardCardForm.vue"),
            meta: {
              permissions: [consts.REWARD_CARD_CREATE],
            },
          },
          {
            path: ":reward_card_id/view",
            name: "RewardCardView",
            component: page("Dashboard/RewardCard/RewardCardForm.vue"),
            meta: {
              permissions: [consts.REWARD_CARD_LIST_VIEW],
            },
          },
          {
            path: ":reward_card_id/edit",
            name: "RewardCardEdit",
            component: page("Dashboard/RewardCard/RewardCardForm.vue"),
            meta: {
              permissions: [consts.REWARD_CARD_MODIFY],
            },
          },
          {
            path: ":reward_card_id/reward-gifts",
            name: "RewardGiftList",
            component: page("Dashboard/RewardGift/RewardGiftList.vue"),
            meta: {
              permissions: [consts.REWARD_GIFT_LIST_VIEW],
            },
          },
          {
            path: ":reward_card_id/reward-missions",
            name: "RewardMissionList",
            component: page("Dashboard/RewardMission/RewardMissionList.vue"),
            meta: {
              permissions: [consts.REWARD_MISSION_LIST_VIEW],
            },
          },
          {
            path: ":reward_card_id/reward-missions/create",
            name: "RewardMissionCreate",
            component: page("Dashboard/RewardMission/RewardMissionForm.vue"),
            meta: {
              permissions: [consts.REWARD_MISSION_CREATE],
            },
          },
          {
            path: ":reward_card_id/reward-missions/:reward_mission_id/view",
            name: "RewardMissionView",
            component: page("Dashboard/RewardMission/RewardMissionForm.vue"),
            meta: {
              permissions: [consts.REWARD_MISSION_LIST_VIEW],
            },
          },
          {
            path: ":reward_card_id/reward-missions/:reward_mission_id/edit",
            name: "RewardMissionEdit",
            component: page("Dashboard/RewardMission/RewardMissionForm.vue"),
            meta: {
              permissions: [consts.REWARD_MISSION_MODIFY],
            },
          },
        ],
      },

      /** 後台 發票管理 */
      {
        path: "invoices",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "InvoiceList",
            component: page("Dashboard/Invoice/InvoiceList.vue"),
            meta: {
              permissions: [consts.INVOICE_LIST_VIEW],
            },
          },
          {
            path: ":invoiceId",
            name: "InvoiceDetail",
            component: page("Dashboard/Invoice/InvoiceDetail.vue"),
          },
        ],
      },

      /** 後台 入點比例管理 */
      {
        path: "point-rules",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "PointRuleList",
            component: page("Dashboard/PointRule/PointRuleList.vue"),
            meta: {
              permissions: [consts.POINT_RULE_VIEW],
            },
          },
          {
            path: "create",
            name: "PointRuleCreate",
            component: page("Dashboard/PointRule/PointRuleForm.vue"),
            meta: {
              permissions: [consts.POINT_RULE_CREATE],
            },
          },
          {
            path: ":pointRuleId",
            name: "PointRuleDetail",
            component: page("Dashboard/PointRule/PointRuleDetail.vue"),
            meta: {
              permissions: [consts.POINT_RULE_VIEW],
            },
          },
          {
            path: ":pointRuleId/edit",
            name: "PointRuleEdit",
            component: page("Dashboard/PointRule/PointRuleForm.vue"),
            meta: {
              permissions: [consts.POINT_RULE_MODIFY],
            },
          },
        ],
      },

      /** 後台 發票審核 */
      {
        path: "invoice-logs",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "InvoiceLogList",
            component: page("Dashboard/InvoiceLog/InvoiceLogList.vue"),
            meta: {
              permissions: [consts.INVOICE_LOG_LIST_VIEW],
            },
          },
          {
            path: ":invoiceLogId/verify",
            name: "InvoiceLogVerify",
            component: page("Dashboard/InvoiceLog/InvoiceLogVerify.vue"),
            meta: {
              permissions: [consts.INVOICE_LOG_VERIFY],
            },
          },
          {
            path: ":invoiceLogId/view",
            name: "InvoiceLogView",
            component: page("Dashboard/InvoiceLog/InvoiceLogEdit.vue"),
            meta: {
              permissions: [consts.INVOICE_LOG_MODIFY],
            },
          },
          {
            path: ":invoiceLogId/edit",
            name: "InvoiceLogEdit",
            component: page("Dashboard/InvoiceLog/InvoiceLogEdit.vue"),
            meta: {
              permissions: [consts.INVOICE_LOG_MODIFY],
            },
          },
        ],
      },
      /** 後台 載具登錄列表 */
      {
        path: "invoices_carriers",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "InvoiceCarrierList",
            component: page("Dashboard/InvoiceCarrier/InvoiceCarrierList.vue"),
            meta: {
              permissions: [consts.INVOICE_CARRIER_VIEW],
            },
          },
        ],
      },
      /** 後台 系統紀錄 */
      {
        path: "server-logs",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "ServerLogList",
            component: page("Dashboard/ServerLog/ServerLogList.vue"),
            meta: {
              permissions: [consts.LOGS_LIST_VIEW],
            },
          },
        ],
      },
      /** 後台 自動化行銷 */
      {
        path: "auto-marketing",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "list",
            name: "AutoMarketingList",
            component: page("Dashboard/AutoMarketing/AutoMarketingList.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: "member-event/create/:type",
            name: "AutoMarketingMemberEventForm",
            component: page("Dashboard/AutoMarketing/AutoMarketingMemberEventForm.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: "member-event",
            name: "AutoMarketingMemberEvent",
            component: page("Dashboard/AutoMarketing/AutoMarketingMemberEvent.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: "create",
            name: "AutoMarketingCreate",
            component: page("Dashboard/AutoMarketing/AutoMarketingForm.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: "member-event/:config_id/edit",
            name: "AutoMarketingEdit",
            component: page("Dashboard/AutoMarketing/AutoMarketingMemberEventForm.vue"),
            meta: {
              permissions: [],
            },
          },
          {
            path: "member-event/:config_id/view",
            name: "AutoMarketingView",
            component: page("Dashboard/AutoMarketing/AutoMarketingMemberEventForm.vue"),
            meta: {
              permissions: [],
            },
          },
        ],
      },
      /** 後台 徽章管理 */
      {
        path: "badges",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "BadgeList",
            component: page("Dashboard/Badge/BadgeList.vue"),
            meta: {
              permissions: [consts.BADGE_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "BadgeCreate",
            component: page("Dashboard/Badge/BadgeForm.vue"),
            meta: {
              permissions: [consts.BADGE_CREATE],
            },
          },
          {
            path: ":badgeId",
            name: "BadgeDetail",
            component: page("Dashboard/Badge/BadgeForm.vue"),
            meta: {
              permissions: [consts.BADGE_LIST_VIEW],
            },
          },
          {
            path: ":badgeId/edit",
            name: "BadgeEdit",
            component: page("Dashboard/Badge/BadgeForm.vue"),
            meta: {
              permissions: [consts.BADGE_EDIT],
            },
          },
          {
            path: ":badgeId/customer-list",
            name: "BadgeCustomerList",
            component: page("Dashboard/Badge/BadgeCustomerList.vue"),
            meta: {
              permissions: [consts.BADGE_EDIT],
            },
          },
        ],
      },

      /** 後台 行銷規則管理 */
      {
        path: "marketing_rules",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "MarketingRuleList",
            component: page("Dashboard/MarketingRule/MarketingRuleList.vue"),
            meta: {
              permissions: [consts.MKT_RULE_LIST_VIEW],
            },
          },
          {
            path: "create",
            name: "MarketingRuleCreate",
            component: page("Dashboard/MarketingRule/MarketingRuleForm.vue"),
            meta: {
              permissions: [consts.MKT_RULE_LIST_CREATE],
            },
          },
          {
            path: ":marketingRuleId",
            name: "MarketingRuleDetail",
            component: page("Dashboard/MarketingRule/MarketingRuleForm.vue"),
            meta: {
              permissions: [consts.MKT_RULE_LIST_VIEW],
            },
          },
          {
            path: ":marketingRuleId/edit",
            name: "MarketingRuleEdit",
            component: page("Dashboard/MarketingRule/MarketingRuleForm.vue"),
            meta: {
              permissions: [consts.MKT_RULE_LIST_MODIFY],
            },
          },
        ],
      },

      /** 後台 LIFF 啟用網址列表畫面 */
      {
        path: "liff-page-list",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "LiffPageList",
            component: page("Dashboard/LiffPage/LiffPageList.vue"),
            meta: {
              permissions: [consts.SYSTEM_INFO],
            },
          },
        ],
      },

      /** 後台 會員卡畫面 */
      {
        path: "liff-card-setting",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "LiffCardSetting",
            component: page("Dashboard/LiffCard/LiffCardSetting.vue"),
            meta: {
              permissions: [consts.LIFF_CARD_SETTING_MODIFY],
              middleware: [loadModules],
              modules: ['member_center'],
            },
          },
        ],
      },

      /** 後台 會員介面設定 */
      {
        path: "liff-ui-setting",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "LiffUISetting",
            component: page("Dashboard/LiffUI/LiffUISetting.vue"),
            meta: {
              permissions: [consts.LIFF_UI_SETTING_MODIFY],
            },
          },
        ],
      },

      {
        path: "suites",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "SuiteList",
            component: page("Dashboard/Suite/SuiteList.vue"),
            meta: {
              permissions: [consts.SUITE_SETTING],
            },
          },
        ],
      },
      /** 後台 LINE 參數設定 */
      {
        path: "line_setting",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "LineSetting",
            component: page("Dashboard/LineSetting/LineSettingForm.vue"),
            meta: {
              permissions: [consts.LINE_SETTING_MODIFY],
            },
          },
        ],
      },

      /** 後台 資料匯出畫面 */
      {
        path: "download-files",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "DashboardDownlondFile",
            component: page("Dashboard/DownloadFile/Index.vue"),
            meta: {
              //permissions: [consts.LIFF_CARD_SETTING_MODIFY],
            },
          },
        ],
      },
      {
        path: "forms",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "list",
            name: "FormList",
            component: page("Dashboard/Form/FormList.vue"),
          },
          {
            path: "create",
            name: "FormCreate",
            component: page("Dashboard/Form/FormForm.vue"),
          },
          {
            path: "edit/:id",
            name: "FormEdit",
            component: page("Dashboard/Form/FormForm.vue"),
          },
          {
            path: "results/:id",
            name: "FormResultList",
            component: page("Dashboard/Form/FormResultList.vue"),
          },
        ]
      },
      {
        path: "collections",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "list",
            name: "CollectionList",
            component: page("Dashboard/Collection/CollectionList.vue"),
          },
          {
            path: "create",
            name: "CollectionCreate",
            component: page("Dashboard/Collection/CollectionForm.vue"),
          },
          {
            path: "edit/:id",
            name: "CollectionEdit",
            component: page("Dashboard/Collection/CollectionForm.vue"),
          },
          {
            path: "items/:id",
            name: "CollectionItemList",
            component: page("Dashboard/Collection/CollectionItemList.vue"),
          },
          {
            path: "items/:id/create",
            name: "CollectionItemCreate",
            component: page("Dashboard/Collection/CollectionItemForm.vue"),
          },
          {
            path: "items/:id/edit/:item_id",
            name: "CollectionItemEdit",
            component: page("Dashboard/Collection/CollectionItemForm.vue"),
          },
        ],
      },
      {
        path: "booking-center",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "booking-calendar",
            name: "BookingCenterCalendar",
            component: page("Dashboard/Booking/BookingCalendar.vue"),
          },
          {
            path: "bookings/:id",
            name: "BookingCenterBookingList",
            component: page("Dashboard/Booking/BookingList.vue"),
          },
          {
            path: "bookings/:id/create",
            name: "BookingCenterBookingCreate",
            component: page("Dashboard/Booking/BookingForm.vue"),
          },
          {
            path: "bookings/:id/edit/:booking_id",
            name: "BookingCenterBookingEdit",
            component: page("Dashboard/Booking/BookingForm.vue"),
          },
        ],
      },
      {
        path: "booking-presets",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "list",
            name: "BookingPresetList",
            component: page("Dashboard/BookingPreset/BookingPresetList.vue"),
          },
          {
            path: "create",
            name: "BookingPresetCreate",
            component: page("Dashboard/BookingPreset/BookingPresetForm.vue"),
          },
          {
            path: "edit/:id",
            name: "BookingPresetEdit",
            component: page("Dashboard/BookingPreset/BookingPresetForm.vue"),
          },
          {
            path: "bookings/select",
            name: "BookingSelectList",
            component: page("Dashboard/Booking/BookingSelectList.vue"),
          },
          {
            path: "bookings/:id",
            name: "BookingList",
            component: page("Dashboard/Booking/BookingList.vue"),
          },
          {
            path: "bookings/:id/create",
            name: "BookingCreate",
            component: page("Dashboard/Booking/BookingForm.vue"),
          },
          {
            path: "bookings/:id/edit/:booking_id",
            name: "BookingEdit",
            component: page("Dashboard/Booking/BookingForm.vue"),
          },
        ],
      },
      {
        path: "data-chart",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "partner-worker-data",
            name: "PartnerWorkerDataChart",
            component: page("Dashboard/DataChart/PartnerWorkerDataChartList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_REPORT_LIST],
            },
          },
          {
            path: "partner-worker-join-line-by-month",
            name: "PartnerWorkerJoinLineByMonthDataChart",
            component: page("Dashboard/DataChart/PartnerWorkerByMonthChartList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_REPORT_LIST],
            },
          },
          {
            path: "staff-join-line-by-month",
            name: "StaffByMonthDataChart",
            component: page("Dashboard/DataChart/StaffByMonthChartList.vue"),
            meta: {
              permissions: [consts.PARTNER_WORKER_REPORT_LIST],
            },
          },
        ],
      },

      {
        path: "shopline_setting",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          permissions: [consts.SHOPLINE_SETTING_MODIFY],
        },
        children: [
          {
            path: "",
            name: "ShoplineSetting",
            component: page("Dashboard/Shopline/Setting.vue"),
          },
          {
            path: "edit",
            name: "ShoplineSettingForm",
            component: page("Dashboard/Shopline/SettingForm.vue"),
          }
        ],
      },
    ],
  },
  {
    name: "Error404",
    path: "*",
    component: page("Error/Error404.vue"),
  },
];
